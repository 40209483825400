/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import '../../../styles/notifications.scss';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {
  // sendEmailReceiptToUser,
  sendPushNotificationToAllUsers,
  sendPushNotificationToUser,
} from '../../../services/notification-requests';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allTitle: '',
      allBody: '',
      oneTitle: '',
      oneBody: '',
      oneUID: '',
      toastOpen: false,
      toastSeverity: '',
      toastMessage: '',
      // user: {},
    };
  }

  sendToAll = () => {
    sendPushNotificationToAllUsers(this.state.allTitle, this.state.allBody)
      .then(() => {
        this.setState({
          toastOpen: true,
          toastSeverity: 'success',
          toastMessage: 'Successfully sent push notification',
        });
      })
      .catch((error) => {
        this.setState({
          toastOpen: true,
          toastSeverity: 'error',
          toastMessage: `Failed to send push notification: ${error}`,
        });
      });
  }

  sendToOne = () => {
    sendPushNotificationToUser(this.state.oneTitle, this.state.oneBody, this.state.oneUID)
      .then(() => {
        this.setState({
          toastOpen: true,
          toastSeverity: 'success',
          toastMessage: 'Successfully sent push notification',
        });
      })
      .catch((error) => {
        this.setState({
          toastOpen: true,
          toastSeverity: 'error',
          toastMessage: `Failed to send push notification: ${error}`,
        });
      });
  }

  toastClosed = () => {
    this.setState({
      toastOpen: false,
      toastSeverity: '',
      toastMessage: '',
    });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className="paper-container">
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>Notifications</b>

              <div id="selector-container">
                <p>Select User</p>
              </div>

              <div id="send-to-all-container">
                <h4>Send Push Notification to All Users</h4>
                <div className="entry-container">
                  <TextField
                    required
                    margin="dense"
                    label="Notification title"
                    defaultValue={this.state.allTitle}
                    onChange={e => this.setState({ allTitle: e.target.value })}
                    className="notification-field"
                  />
                  <TextField
                    required
                    margin="dense"
                    label="Notification body"
                    defaultValue={this.state.allBody}
                    onChange={e => this.setState({ allBody: e.target.value })}
                    className="notification-field"
                  />
                  <Button
                    className="notification-button"
                    variant="outlined"
                    color="primary"
                    onClick={this.sendToAll}
                  >
                    Send Notification
                  </Button>
                </div>
              </div>
              <div id="send-to-one-container">
                <h4>Send Push Notification to Specific User</h4>
                <div className="entry-container">
                  <TextField
                    required
                    margin="dense"
                    label="Notification title"
                    defaultValue={this.state.oneTitle}
                    onChange={e => this.setState({ oneTitle: e.target.value })}
                    className="notification-field"
                  />
                  <TextField
                    required
                    margin="dense"
                    label="Notification body"
                    defaultValue={this.state.oneBody}
                    onChange={e => this.setState({ oneBody: e.target.value })}
                    className="notification-field"
                  />
                  <TextField
                    required
                    margin="dense"
                    label="User ID (available in users tab)"
                    defaultValue={this.state.oneUID}
                    onChange={e => this.setState({ oneUID: e.target.value })}
                    className="notification-field"
                  />
                  <Button
                    className="notification-button"
                    variant="outlined"
                    color="primary"
                    onClick={this.sendToOne}
                  >
                    Send Notification
                  </Button>
                </div>
              </div>
              <Snackbar open={this.state.toastOpen} autoHideDuration={6000} onClose={this.toastClosed}>
                <Alert onClose={this.toastClosed} severity={this.state.toastSeverity}>
                  {this.state.toastMessage}
                </Alert>
              </Snackbar>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users.users,
    companies: state.companies.companies,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Notifications);
