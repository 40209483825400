/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import '../../../styles/companies.scss';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import SettingPreview from './settings-preview';
import ViewSetting from './view-setting';
import { createSetting, updateSetting } from '../../../state/actions';

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      setting: null,
      add: false,
    };
  }

  handleSettingClick = (setting) => {
    this.setState({
      setting,
    });
  };

  renderSettings = () => {
    return this.props.settings.map((setting) => {
      return <SettingPreview setting={setting} handleClick={this.handleSettingClick} />;
    });
  };

  createSetting = (fields) => {
    this.props.createSetting(fields);
  }

  updateSetting = (fields) => {
    this.props.updateSetting(fields);
  }

  handleModalClose = () => {
    this.setState({
      setting: null,
      add: false,
    });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className="paper-container">
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>Settings/Dictionary Keys</b>
              <p>Please do not change these without consulting Thomas</p>
              <div id="private-company-container">
                {this.renderSettings()}
              </div>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={this.props.fixedHeightPaperModal}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>Add New Setting</b>
            </Typography>
            <Fab variant="extended"
              aria-label="like"
              style={{
                color: 'white', fontFamily: 'avenir', fontWeight: 'bold', backgroundColor: '#008037',
              }}
              onClick={() => { this.setState({ add: true }); }}
            >
              Add New Setting
            </Fab>
          </Paper>
        </Grid>

        <ViewSetting
          setting={this.state.setting}
          handleClose={this.handleModalClose}
          updateSetting={this.updateSetting}
        />

        <ViewSetting
          handleClose={this.handleModalClose}
          createSetting={this.createSetting}
          add={this.state.add}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSetting: (fields) => {
      dispatch(createSetting(fields));
    },
    updateSetting: (fields) => {
      dispatch(updateSetting(fields));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);
