/* eslint-disable no-param-reassign */
import * as settingsRequests from '../../services/settings-requests';

// keys for actiontypes
const ActionTypes = {
  GET_ALL_SETTINGS: 'GET_ALL_SETTINGS',
};

const getAllSettings = () => {
  return async (dispatch) => {
    const data = await settingsRequests.getAllSettings();
    dispatch({ type: ActionTypes.GET_ALL_SETTINGS, payload: data });
  };
};

const getSetting = (id) => {
  return async (dispatch) => {
    await settingsRequests.getSetting(id);
    const data = await settingsRequests.getAllSettings();
    dispatch({ type: ActionTypes.GET_ALL_SETTINGS, payload: data });
  };
};

const createSetting = (fields) => {
  return async (dispatch) => {
    await settingsRequests.createSetting(fields);
    const data = await settingsRequests.getAllSettings();
    dispatch({ type: ActionTypes.GET_ALL_SETTINGS, payload: data });
  };
};

const updateSetting = (fields) => {
  return async (dispatch) => {
    await settingsRequests.updateSetting(fields);
    const data = await settingsRequests.getAllSettings();
    dispatch({ type: ActionTypes.GET_ALL_SETTINGS, payload: data });
  };
};

const deleteSetting = (id) => {
  return async (dispatch) => {
    await settingsRequests.deleteSetting(id);
    const data = await settingsRequests.getAllSettings();
    dispatch({ type: ActionTypes.GET_ALL_SETTINGS, payload: data });
  };
};

export {
  ActionTypes,
  getAllSettings,
  getSetting,
  createSetting,
  updateSetting,
  deleteSetting,
};
