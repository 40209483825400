/* eslint-disable no-case-declarations */
import { ActionTypes } from '../actions';

const initialState = {
  settings: [],
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_SETTINGS:
      return { ...state, settings: action.payload };
    default:
      return state;
  }
};

export default SettingsReducer;
