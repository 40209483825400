import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import Kitchen from '@material-ui/icons/Kitchen';
import Typography from '@material-ui/core/Typography';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import GetAppIcon from '@material-ui/icons/GetApp';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

import { TAB_PATHS } from '../../constants';
import '../../styles/list-items.scss';

const SideBar = (props) => {
  return (
    <div id="list-items">
      <Link to={TAB_PATHS.ORDERS} exact>
        <ListItem button>
          <ListItemIcon>
            <ShoppingCartOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.ORDERS) ? 'bold' : 'light' }}>Mobile Orders</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.POS_SALES} exact>
        <ListItem button>
          <ListItemIcon>
            <AssessmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.POS_SALES) ? 'bold' : 'light' }}>POS Sales</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.INVENTORY} exact>
        <ListItem button>
          <ListItemIcon>
            <AssignmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.INVENTORY) ? 'bold' : 'light' }}>POS Inventory</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.COMPANIES} exact>
        <ListItem button>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.COMPANIES) ? 'bold' : 'light' }}>Companies</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.FRIDGES} exact>
        <ListItem button>
          <ListItemIcon>
            <Kitchen />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.FRIDGES) ? 'bold' : 'light' }}>Fridges</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.PRODUCTS} exact>
        <ListItem button>
          <ListItemIcon>
            <FastfoodOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.PRODUCTS) ? 'bold' : 'light' }}>Products</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.PROMOTIONS} exact>
        <ListItem button>
          <ListItemIcon>
            <CardGiftcard />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.PROMOTIONS) ? 'bold' : 'light' }}>Promotions</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.USERS} exact>
        <ListItem button>
          <ListItemIcon>
            <PeopleAltOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.USERS) ? 'bold' : 'light' }}>Users</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.NOTIFICATIONS} exact>
        <ListItem button>
          <ListItemIcon>
            <NotificationsNoneIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.NOTIFICATIONS) ? 'bold' : 'light' }}>Notifications</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.POS_DEVICES} exact>
        <ListItem button>
          <ListItemIcon>
            <TabletMacIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.POS_DEVICES) ? 'bold' : 'light' }}>POS Devices</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.POS_DOWNLOADS} exact>
        <ListItem button>
          <ListItemIcon>
            <GetAppIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.POS_DOWNLOADS) ? 'bold' : 'light' }}>Download POS</Typography>}
          />
        </ListItem>
      </Link>

      <Link to={TAB_PATHS.SETTINGS} exact>
        <ListItem button>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ fontFamily: 'avenir', fontWeight: (props.history.location.pathname === TAB_PATHS.SETTINGS) ? 'bold' : 'light' }}>Settings</Typography>}
          />
        </ListItem>
      </Link>
    </div>
  );
};

export default withRouter(SideBar);
