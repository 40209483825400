/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Edit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { connect } from 'react-redux';

import '../../../styles/users.scss';

class ViewUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      private_companies: [],
      private_fridges: [],
      active_discount: {},
      admin_user: false,
      stripe_test_mode: false,
    };
  }

  submit = () => {
    this.props.setUserPrivateCompanies(this.props.user._id, this.state.private_companies);
    this.props.setUserPrivateFridges(this.props.user._id, this.state.private_fridges);
    this.props.setUserActiveDiscount(this.props.user._id, this.state.active_discount);
    this.props.setUserAdminUser(this.props.user._id, this.state.admin_user);
    this.props.setUserStripeTestMode(this.props.user._id, this.state.stripe_test_mode);

    this.close();
  }

  close = () => {
    this.setState({
      private_companies: [],
      private_fridges: [],
      active_discount: {},
      admin_user: false,
      stripe_test_mode: false,
    });

    this.props.handleClose();
  }

  renderPrivateCompanies = () => {
    return (
      <div>
        {this.state.private_companies.map((company) => {
          return (
            <FormControlLabel
              control={(
                <Checkbox
                  checked
                  onChange={(e) => {
                    const newCompanies = [];
                    this.state.private_companies.forEach((privCompany) => {
                      if (privCompany !== company) {
                        newCompanies.push(privCompany);
                      }
                    });

                    this.setState({
                      private_companies: newCompanies,
                    });
                  }}
                  value="is_public"
                />
                  )}
              label={this.props.companies.find(aCompany => aCompany.id === company) ? this.props.companies.find(aCompany => aCompany.id === company).name : ''}
            />
          );
        })}
      </div>
    );
  }

  addPrivateCompany = (id) => {
    const newCompanies = [];
    this.state.private_companies.forEach((privCompany) => {
      newCompanies.push(privCompany);
    });

    newCompanies.push(id);

    this.setState({
      private_companies: newCompanies,
    });
  }

  renderAddPrivateCompany = () => {
    if (this.state.is_public) {
      return null;
    }

    const numMenuItems = this.props.companies.filter((company) => {
      return !this.state.private_companies.includes(company.id);
    }).length;

    if (numMenuItems === 0) {
      return null;
    }

    return (
      <div style={{
        display: 'flex', justifyContent: 'flex-start', marginTop: '20px', marginBottom: '20px',
      }}
      >
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                Add Private Company
              </Button>
              <Menu {...bindMenu(popupState)}>
                {this.props.companies.map((company) => {
                  if (!this.state.private_companies.includes(company.id)) {
                    return (
                      <MenuItem onClick={(e) => {
                        popupState.close(e);
                        this.addPrivateCompany(company.id);
                      }}
                      >{company.name}
                      </MenuItem>
                    );
                  }

                  return null;
                })}
              </Menu>
            </>
          )}
        </PopupState>
      </div>
    );
  }

  renderPrivateFridges = () => {
    return (
      <div>
        {this.state.private_fridges.map((fridge) => {
          return (
            <FormControlLabel
              control={(
                <Checkbox
                  checked
                  onChange={(e) => {
                    const newFridges = [];
                    this.state.private_fridges.forEach((privFridge) => {
                      if (privFridge !== fridge) {
                        newFridges.push(fridge);
                      }
                    });

                    this.setState({
                      private_fridges: newFridges,
                    });
                  }}
                  value="is_public"
                />
                  )}
              label={this.props.fridges.find(aFridge => aFridge.id === fridge) ? this.props.fridges.find(aFridge => aFridge.id === fridge).name : ''}
            />
          );
        })}
      </div>
    );
  }

  addPrivateFridge = (id) => {
    const newFridges = [];
    this.state.private_fridges.forEach((privFridge) => {
      newFridges.push(privFridge);
    });

    newFridges.push(id);

    this.setState({
      private_fridges: newFridges,
    });
  }

  renderAddPrivateFridge = () => {
    if (this.state.is_public) {
      return null;
    }

    const menuItems = this.props.fridges.filter((fridge) => {
      let found = false;

      this.state.private_companies.forEach((company) => {
        if (fridge.private_companies.includes(company) && !found) {
          found = true;
        }
      });

      return !this.state.private_fridges.includes(fridge.id) && !found;
    });

    if (menuItems.length === 0) {
      return null;
    }

    return (
      <div style={{
        display: 'flex', justifyContent: 'flex-start', marginTop: '20px', marginBottom: '20px',
      }}
      >
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                Add Private Fridge
              </Button>
              <Menu {...bindMenu(popupState)}>
                {menuItems.map((fridge) => {
                  return (
                    <MenuItem onClick={(e) => {
                      popupState.close(e);
                      this.addPrivateFridge(fridge.id);
                    }}
                    >{fridge.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </PopupState>
      </div>
    );
  }

  renderActiveDiscount = () => {
    if (Object.keys(this.state.active_discount).length === 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '5px', marginRight: '5px' }}
            onClick={() => {
              this.setState({
                active_discount: {
                  amount: 0,
                  percent: true,
                  persist: false,
                  title: 'New Discount',
                  message: 'Enjoy a new discount on us!',
                  imageID: '1OPvEUOuwKSeGAM1RxxF8Lp9wB0GjrxcJ',
                },
              });
            }}
          >
            Add Discount
          </Button>
        </div>
      );
    }

    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={this.state.active_discount.percent}
              onChange={() => {
                const newObj = { ...this.state.active_discount };
                newObj.percent = !newObj.percent;
                this.setState({ active_discount: newObj });
              }}
              value="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
          label="Percentage off"
        />

        <FormControlLabel
          control={(
            <Checkbox
              checked={!this.state.active_discount.percent}
              onChange={() => {
                const newObj = { ...this.state.active_discount };
                newObj.percent = !newObj.percent;
                this.setState({ active_discount: newObj });
              }}
              value="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
          label="Dollar amount off"
        />

        <FormControlLabel
          control={(
            <Checkbox
              checked={!this.state.active_discount.persist}
              onChange={() => {
                const newObj = { ...this.state.active_discount };
                newObj.persist = !newObj.persist;
                this.setState({ active_discount: newObj });
              }}
              value="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
          label="One time discount"
        />

        <TextField
          required
          margin="dense"
          label="Amount (if percent between 0 and 1)"
          defaultValue={this.state.active_discount.amount}
          onChange={(e) => {
            const newObj = { ...this.state.active_discount };
            newObj.amount = e.target.value;
            this.setState({ active_discount: newObj });
          }}
          fullWidth
        />

        <TextField
          required
          margin="dense"
          label="Title"
          defaultValue={this.state.active_discount.title}
          onChange={(e) => {
            const newObj = { ...this.state.active_discount };
            newObj.title = e.target.value;
            this.setState({ active_discount: newObj });
          }}
          fullWidth
        />

        <TextField
          required
          margin="dense"
          label="Message"
          defaultValue={this.state.active_discount.message}
          onChange={(e) => {
            const newObj = { ...this.state.active_discount };
            newObj.message = e.target.value;
            this.setState({ active_discount: newObj });
          }}
          fullWidth
        />

        <TextField
          required
          margin="dense"
          label="Image ID (leave empty if don't want to appear)"
          defaultValue={this.state.active_discount.imageID}
          onChange={(e) => {
            const newObj = { ...this.state.active_discount };
            newObj.imageID = e.target.value;
            this.setState({ active_discount: newObj });
          }}
          fullWidth
        />

        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '5px', marginRight: '5px' }}
            onClick={() => {
              this.setState({
                active_discount: {},
              });
            }}
          >
            Remove Discount
          </Button>
        </div>
      </div>
    );
  }

  renderAdminUser = () => {
    return (
      <FormControlLabel
        control={(
          <Checkbox
            checked={this.state.admin_user}
            onChange={() => { this.setState({ admin_user: !this.state.admin_user }); }}
            value="is_public"
          />
                  )}
        label="Admin User"
      />
    );
  }

  renderStripeTestMode = () => {
    return (
      <FormControlLabel
        control={(
          <Checkbox
            checked={this.state.stripe_test_mode}
            onChange={() => { this.setState({ stripe_test_mode: !this.state.stripe_test_mode }); }}
            value="stripe_test_mode"
          />
                  )}
        label="Test Credit Cards"
      />
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if (props.user) {
      this.setState({
        private_companies: props.user.private_companies,
        private_fridges: props.user.private_fridges,
        active_discount: props.user.active_discount || {},
        admin_user: props.user.admin_user || false,
        stripe_test_mode: props.user.stripe_test_mode || false,
      });
    }
  }

  render() {
    if (this.props.user) {
      return (
        <div>
          <Dialog open onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{this.props.user ? `${this.props.user.first_name} ${this.props.user.last_name}` : 'View User'}</DialogTitle>
            <DialogContent style={{ marginTop: '-15px', width: '450px' }}>
              <DialogContentText>
                Edit the private companies available to this user below
              </DialogContentText>

              {this.renderPrivateCompanies()}
              {this.renderAddPrivateCompany()}

              <div className="line" />

              <DialogContentText>
                Edit the private fridges available to this user below (these are manual, not associated with a company)
              </DialogContentText>

              {this.renderPrivateFridges()}
              {this.renderAddPrivateFridge()}

              <div className="line" />

              <DialogContentText>
                Edit or add a discount to this user below
              </DialogContentText>

              {this.renderActiveDiscount()}

              <div className="line" />

              <DialogContentText>
                Change the user&apos;s admin status
              </DialogContentText>

              {this.renderAdminUser()}

              <DialogContentText>
                Change the user&apos;s credit card test mode
              </DialogContentText>

              {this.renderStripeTestMode()}

              <div className="line" />

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Button variant="outlined"
                  color="primary"
                  onClick={this.submit}
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                >
                  Submit
                </Button>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.close} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companies.companies,
    fridges: state.fridges.fridges,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ViewUser);
