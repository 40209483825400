import React, { useEffect } from 'react';
import * as firebase from 'firebase';

import fetchAppConfig from '../../services/app-requests';
import { getOAuthToken } from '../../services/user-requests';

const signInGoogle = async () => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider);
};

const getRedirectToken = async () => {
  const result = await firebase.auth().getRedirectResult();

  if (!result.user) return null;

  const {
    user: {
      uid,
    },
  } = result;

  await fetchAppConfig();
  const customToken = await getOAuthToken(uid);

  return customToken;
};

const initializeAuthFlow = async () => {
  try {
    const idToken = await getRedirectToken();

    if (idToken) {
      window.location.href = `sixamhealth://oauth-success/?token=${idToken}`;
    } else {
      await signInGoogle();
    }
  } catch (error) {
    console.log(error);
  }
};

const GoogleOAuth = () => {
  useEffect(() => initializeAuthFlow(), []);
  return <div />;
};

export default GoogleOAuth;
