import * as tagRequests from '../../services/tag-requests';

// keys for actiontypes
const ActionTypes = {
  GET_6AM_TAGS: 'GET_6AM_TAGS',
};

const get6AMTags = () => {
  return async (dispatch) => {
    try {
      const data = await tagRequests.getAllTags();
      dispatch({ type: ActionTypes.GET_6AM_TAGS, payload: data });
    } catch (error) {
      console.error(`6am tags data not received: ${error.message}`);
      throw error;
    }
  };
};

const create6AMTag = (fields) => {
  return async (dispatch) => {
    await tagRequests.createTag(fields);
    const data = await tagRequests.getAllTags();
    dispatch({ type: ActionTypes.GET_6AM_TAGS, payload: data });
  };
};

const update6AMTag = (fields) => {
  return async (dispatch) => {
    await tagRequests.updateTag(fields);
    const data = await tagRequests.getAllTags();
    dispatch({ type: ActionTypes.GET_6AM_TAGS, payload: data });
  };
};

const delete6AMTag = (id) => {
  return async (dispatch) => {
    await tagRequests.deleteTag(id);
    const data = await tagRequests.getAllTags();
    dispatch({ type: ActionTypes.GET_6AM_TAGS, payload: data });
  };
};

export {
  ActionTypes,
  get6AMTags,
  create6AMTag,
  update6AMTag,
  delete6AMTag,
};
