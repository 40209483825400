/* eslint-disable max-len */
import React from 'react';
import '../../styles/terms.scss';

const Terms = (props) => {
  return (
    <div id="terms">
      <p>This statement was last revised on July 1, 2019.</p>
      <p>PLEASE READ THESE TERMS OF USE CAREFULLY. DOWNLOADING, INSTALLING, ACCESSING OR USING THIS 6AM HEALTH MOBILE APPLICATION (THE “APPLICATION”) CONSTITUTES ACCEPTANCE OF THESE TERMS OF USE (“TERMS”), AS SUCH MAY BE REVISED BY 6AM HEALTH FROM TIME TO TIME, AND IS A BINDING AGREEMENT BETWEEN THE USER (“USER”) AND 6AM HEALTH CORPORATION (D/B/A 6AM HEALTH COFFEE COMPANY) (“6AM HEALTH”) GOVERNING THE USE OF THE APPLICATION. IF USER DOES NOT AGREE TO THESE TERMS, USER SHOULD UNINSTALL THIS APPLICATION IMMEDIATELY. THESE TERMS CONTAIN DISCLAIMERS AND OTHER PROVISIONS THAT LIMIT OUR LIABILITY TO USER.</p>
      <p>These terms include an Arbitration provision that governs any disputes between you and us. Unless you opt out, as described below, this provision will: Eliminate your right to a trial by jury; and substantially affect your rights, including preventing you from bringing, joining or participating in class or consolidated proceedings.You agree that we may provide notices, disclosures and amendments to these Terms by electronic means, including by changing these Terms within the Application or by posting revisions on the 6AM Health Website.</p>
      <h4>Eligibility</h4>
      <p>The Application is not targeted towards, nor intended for use by, anyone under the age of 13. A USER MUST BE AT LEAST AGE 13 TO ACCESS AND USE THE APPLICATION. If the User is between the ages of 13 and 18, he or she may only use the Application under the supervision of a parent or legal guardian who agrees to be bound by these Terms. User represents and warrants that (a) he/she is not located in a country that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a “terrorist supporting” country; and (b) he/she is not listed on any U.S. government list of prohibited or restricted parties.</p>
      <p>In order to use certain functions of our Application, you will need to register for an account. You agree to (a) create only one account; (b) provide accurate, truthful , current and complete information when creating your account; (c) maintain and promptly update your account information; (d) maintain the security of your account by not sharing your password with others and restricting access to your account and your computer; (e) promptly notify 6AM Health if you discover or otherwise suspect any security breaches relating to the Application; and (f) take responsibility for all activities that occur under your account and accept all risks of unauthorized access.</p>
      <h4>Privacy</h4>
      <p>Please read the Privacy Policy carefully to understand how 6AM Health collects, uses and discloses personally identifiable information from its users. By downloading, installing, accessing or using the Application, you consent to all actions that we take with respect to your data consistent with our Privacy Policy.</p>
      <p>Apple Terms and Conditions; 6AM Health Policies</p>
      <p>These Terms supplement and incorporate (a) the Apple, Inc. (“Apple”) Terms and Conditions (located at http://www.apple.com/legal/internet-services/itunes/us/terms.html) including, without limitation, the Licensed Application End User License Agreement provided therein (“Apple Terms”); and (b) other 6AM Health policies, including 6AM Health® Rewards, posted at www.6AM Health.com (“6AM Health Website”). If any of the provisions of the Apple Terms and Conditions conflict with these Terms, the Apple Terms and Conditions will control, solely to the extent such terms apply to the Application. 6AM Health, not Apple, is solely responsible for the Application and the content thereof.</p>
      <h4>Mobile Payment</h4>
      <p>Users who download the Application to a Device may also elect to participate in certain functionality of the Application which will allow the User to use a Device to purchase 6AM Health products in the same manner as is possible with a 6AM Health Card (“Mobile Payment”). Mobile Payment is accepted at all company operated 6AM Health retail locations and some 6AM Health licensed stores. 6AM Health reserves the right at any time to discontinue Mobile Payment or change the location of stores accepting Mobile Payment.</p>
    </div>
  );
};

export default Terms;
