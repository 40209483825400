/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Edit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class ViewSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      value: true,
      data: {},
    };
  }

  submit = () => {
    if (this.props.add) {
      this.props.createSetting(this.state);
    } else {
      this.props.updateSetting(this.state);
    }

    this.close();
  }

  close = () => {
    this.setState({
      id: '',
      value: true,
      data: {},
    });

    this.props.handleClose();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if (props.setting) {
      this.setState({
        id: props.setting._id,
        value: props.setting.value,
        data: props.setting.data,
      });
    }
  }

  render() {
    if (this.props.setting || this.props.add) {
      return (
        <div>
          <Dialog open onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{this.props.setting ? this.props.setting.id : 'Create Setting'}</DialogTitle>
            <DialogContent style={{ marginTop: '-15px', width: '550px' }}>
              <DialogContentText>
                Edit details about this setting below
              </DialogContentText>
              <TextField
                required
                margin="dense"
                label="Setting name"
                defaultValue={this.state.id}
                onChange={e => this.setState({ id: e.target.value })}
                fullWidth
                disabled
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={this.state.value}
                    onChange={() => { this.setState({ value: !this.state.value }); }}
                    value="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
          )}
                label="Active"
              />
              <TextField
                required
                margin="dense"
                label="Metadata/dictionary value"
                defaultValue={JSON.stringify(this.state.data, null, '\t')}
                onChange={e => this.setState({ data: JSON.parse(e.target.value) })}
                multiline
                fullWidth
              />
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Button variant="outlined"
                  color="primary"
                  onClick={this.submit}
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                >
                  Submit
                </Button>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.close} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }

    return null;
  }
}
