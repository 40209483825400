/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import '../../../styles/companies.scss';

const FridgePreview = (props) => {
  const [imgError, setImgError] = useState(false);

  return (
    <div className="company-preview-area" onClick={() => { props.handleClick(props.fridge); }}>
      <p>{`Fridge Name: ${props.fridge.name}`}</p>
      <p>{`General Location: ${props.fridge.location_general}`}</p>
      <p>{`Specific Location: ${props.fridge.location_specific}`}</p>
      <p>{`Tax Rate: ${props.fridge.tax_rate}`}</p>

      <div>
        <p>Private Companies With Access</p>
        <ul>
          {props.fridge.private_companies.map((company) => {
            return <li style={{ fontSize: 16 }}>{props.companies.find(aCompany => aCompany.id === company).name}</li>;
          })}
        </ul>
      </div>
      { props.imgURL && !imgError ? (
        <div className="company-logo">
          <img
            src={props.imgURL}
            alt={props.fridge.name}
            onError={() => {
              setImgError(true);
            }}
          />
        </div>
      ) : null }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: state.companies.companies,
  };
};

export default connect(
  mapStateToProps,
  null,
)(FridgePreview);
