import * as posDeviceRequests from '../../services/pos-device-requests';

// keys for actiontypes
const ActionTypes = {
  GET_POS_DEVICES: 'GET_POS_DEVICES',
  GET_POS_DEVICE: 'GET_POS_DEVICE',
  CREATE_POS_DEVICE: 'CREATE_POS_DEVICE',
  UPDATE_POS_DEVICE: 'UPDATE_POS_DEVICE',
  DELETE_POS_DEVICE: 'DELETE_POS_DEVICE',
};

const fetchAllPOSDevices = () => {
  return async (dispatch) => {
    const data = await posDeviceRequests.fetchAllPOSDevices();
    dispatch({ type: ActionTypes.GET_POS_DEVICES, payload: data });
  };
};

const fetchPOSDeviceById = (id) => {
  return async (dispatch) => {
    const data = await posDeviceRequests.getPOSDeviceById(id);
    dispatch({ type: ActionTypes.GET_POS_DEVICE, payload: data });
  };
};

const createPOSDevice = (id, fields) => {
  return async (dispatch) => {
    const data = await posDeviceRequests.createPOSDevice(id, fields);
    dispatch({ type: ActionTypes.CREATE_POS_DEVICE, payload: data });
  };
};

const updatePOSDeviceById = (id, fields) => {
  return async (dispatch) => {
    const data = await posDeviceRequests.updatePOSDeviceById(id, fields);
    dispatch({ type: ActionTypes.UPDATE_POS_DEVICE, payload: data });
  };
};

const deletePOSDeviceById = (id) => {
  return async (dispatch) => {
    const data = await posDeviceRequests.deletePOSDeviceById(id);
    dispatch({ type: ActionTypes.DELETE_POS_DEVICE, payload: data });
  };
};

export {
  ActionTypes,
  fetchAllPOSDevices,
  fetchPOSDeviceById,
  createPOSDevice,
  updatePOSDeviceById,
  deletePOSDeviceById,
};
