/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import '../../../styles/promotion_box.scss';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PromotionDisplay from './promotion-display';
import AddPromotionWindow from './add-promotion-window';

const Promotions = (props) => {
  const {
    promotions,
    currentPOSPromotions,
    currentMobilePromotions,
  } = props;

  const posPromoIds = currentPOSPromotions.map(promo => promo.id);
  const mobilePromoIds = currentMobilePromotions.map(promo => promo.id);

  const inactivePromotions = promotions
    .filter(promotion => !posPromoIds.includes(promotion.id) && !mobilePromoIds.includes(promotion.id));

  const displayPromotions = (collection) => {
    if (collection && collection.length > 0) {
      return collection.map((item, key) => (
        <div className="promotion">
          <ul>
            { (item.display_content) ? <PromotionDisplay item={item} /> : <p>Data not found.</p>}
          </ul>
          <div className="buttons">
            <AddPromotionWindow messages={{
              buttonText: 'Add New Promotion',
              titleText: 'Promotion Manager',
              dialogueText: 'Modify existing promotion, and hit submit.',
            }}
              type="modify_promotions"
              promotion={item}
            />
          </div>
        </div>
      ));
    }

    return null;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper className="paper-container">
          <Typography component="h1" variant="h6" color="inherit" noWrap className={props.classes.title}>
            <b>Active POS Promotions</b>
            <div className={props.classes.promotionBox}>
              {displayPromotions(currentPOSPromotions)}
            </div>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={props.paperModal}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={props.classes.title}>
            <b>Add New Promotion</b>
          </Typography>
          <p className="suggested-size">Promotion images should be 612 x 284 @72 ppi</p>
          <AddPromotionWindow messages={{
            buttonText: 'Add New Promotion',
            titleText: 'New Promotion',
            dialogueText: 'Enter a new promotion, and hit submit.',
          }}
            type="add"
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <Paper className="paper-container">
          <Typography component="h1" variant="h6" color="inherit" noWrap className={props.classes.title}>
            <b>Active Mobile Promotions</b>
            <div className={props.classes.promotionBox}>
              {displayPromotions(currentMobilePromotions)}
            </div>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <Paper className="paper-container">
          <Typography component="h1" variant="h6" color="inherit" noWrap className={props.classes.title}>
            <b>Inactive Promotions</b>
            <div className={props.classes.promotionBox}>
              {displayPromotions(inactivePromotions)}
            </div>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const {
    promotions: {
      promotions,
      currentPOSPromotions,
      currentMobilePromotions,
    },
  } = state;

  return {
    promotions,
    currentPOSPromotions,
    currentMobilePromotions,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Promotions);
