import axios from 'axios';
import firebase from 'firebase';

const fetchAllPOSDevices = async () => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.get(`${global.API_URL}/device-info`, { headers: { authorization: idToken } });
  return response.data;
};

const fetchPOSDeviceById = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.get(`${global.API_URL}/device-info/${id}`, { headers: { authorization: idToken } });
  return response.data;
};

const createPOSDevice = async (id, fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.post(`${global.API_URL}/device-info/${id}`, fields, { headers: { authorization: idToken } });
  return response.data;
};

const updatePOSDeviceById = async (id, fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/device-info/${id}`, { fields }, { headers: { authorization: idToken } });
  return response.data;
};

const deletePOSDeviceById = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.delete(`${global.API_URL}/device-info/${id}`, { headers: { authorization: idToken } });
  return response.data;
};

export {
  fetchAllPOSDevices,
  fetchPOSDeviceById,
  createPOSDevice,
  updatePOSDeviceById,
  deletePOSDeviceById,
};
