/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import '../../../styles/companies.scss';

export default (props) => {
  return (
    <div className="company-preview-area" onClick={() => { props.handleClick(props.company); }}>
      <p>{`Company Name: ${props.company.name}`}</p>
      <p>Valid Email Extensions</p>
      <ul>
        {props.company.valid_email_extensions.map((extension) => {
          return <li style={{ fontSize: 16 }}>{extension}</li>;
        })}
      </ul>
    </div>
  );
};
