/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-template */
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { connect } from 'react-redux';

class ViewProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: (this.props.product) ? this.props.product._id : '',
      name: (this.props.product) ? this.props.product.name : '',
      description: (this.props.product) ? this.props.product.description : '',
      tags: (this.props.product) ? this.props.product.tags : [],
      ingredients: (this.props.product) ? this.props.product.ingredients : [],
      nutrition_facts: (this.props.product) ? JSON.stringify(this.props.product.nutrition_facts) : JSON.stringify({}),
      private_fridge_exclude: (this.props.product) ? this.props.product.private_fridge_exclude : [],
      gluten_free: (this.props.product) ? this.props.product.gluten_free : false,
      vegan: (this.props.product) ? this.props.product.vegan : false,
      vegetarian: (this.props.product) ? this.props.product.vegetarian : false,
      product_type: (this.props.product) ? this.props.product.product_type : '',
      category: (this.props.product) ? this.props.product.category : '',
      brand: (this.props.product) ? this.props.product.brand : '',
      sku: (this.props.product) ? this.props.product.sku : '',
      price: (this.props.product && this.props.product.price) ? this.props.product.price.toFixed(2) : null,
      shelf_life: (this.props.product) ? this.props.product.price : null,
      image_id: (this.props.product) ? this.props.product.image_id : null,
      visible: (this.props.product) ? this.props.product.visible : null,
    };
  }

  arrayToString = (array) => {
    return array.join(', ');
  }

  stringToArray = (string) => {
    this.setState({
      ingredients: string.split(', '),
    });
  }

  renderPrivateCompanies = () => {
    return (
      <div>
        <DialogContentText style={{ marginTop: '15px' }}>
          Fridges that do NOT receive this product:
        </DialogContentText>
        {this.state.private_fridge_exclude.map((fridge) => {
          return (
            <FormControlLabel
              control={(
                <Checkbox
                  checked
                  onChange={(e) => {
                    const newFridges = [];

                    this.state.private_fridge_exclude.forEach((privFridge) => {
                      if (privFridge !== fridge) {
                        newFridges.push(privFridge);
                      }
                    });

                    this.setState({
                      private_fridge_exclude: newFridges,
                    });
                  }}
                />
                  )}
              label={this.props.fridges.find(aFridge => aFridge.id === fridge) ? this.props.fridges.find(aFridge => aFridge.id === fridge).name : ''}
            />
          );
        })}
      </div>
    );
  }

  addPrivateCompany = (id) => {
    const newCompanies = [];
    this.state.private_fridge_exclude.forEach((privCompany) => {
      newCompanies.push(privCompany);
    });

    newCompanies.push(id);

    this.setState({
      private_fridge_exclude: newCompanies,
    });
  }

  renderAddPrivateCompany = () => {
    const numMenuItems = this.props.fridges.filter((fridge) => {
      return !this.state.private_fridge_exclude.includes(fridge.id);
    }).length;
    if (numMenuItems === 0) {
      return null;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                Add Private Fridge
              </Button>
              <Menu {...bindMenu(popupState)}>
                {this.props.fridges.map((fridge) => {
                  if (!this.state.private_fridge_exclude.includes(fridge.id)) {
                    return (
                      <MenuItem onClick={(e) => {
                        popupState.close(e);
                        this.addPrivateCompany(fridge.id);
                      }}
                      >{fridge.name}
                      </MenuItem>
                    );
                  }

                  return null;
                })}
              </Menu>
            </>
          )}
        </PopupState>
      </div>
    );
  }

  renderTags = () => {
    return (
      <div>
        <DialogContentText style={{ marginTop: '15px' }}>
          Product tags:
        </DialogContentText>
        {this.state.tags.map((tag) => {
          return (
            <FormControlLabel
              control={(
                <Checkbox
                  checked
                  onChange={(e) => {
                    const newTags = [];
                    this.state.tags.forEach((newTag) => {
                      if (newTag !== tag) {
                        newTags.push(newTag);
                      }
                    });

                    this.setState({
                      tags: newTags,
                    });
                  }}
                />
                  )}
              label={this.props.tags.find(aTag => aTag.id === tag) ? this.props.tags.find(aTag => aTag.id === tag).name : ''}
            />
          );
        })}
      </div>
    );
  }

  addTag = (id) => {
    const newTags = [];
    this.state.tags.forEach((tag) => {
      newTags.push(tag);
    });

    newTags.push(id);

    this.setState({
      tags: newTags,
    });
  }

  renderAddTag = () => {
    const numMenuItems = this.props.tags.filter((tag) => {
      return !this.state.tags.includes(tag.id);
    }).length;

    if (numMenuItems === 0) {
      return null;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                Add Tag
              </Button>
              <Menu {...bindMenu(popupState)}>
                {this.props.tags.map((tag) => {
                  if (!this.state.tags.includes(tag.id)) {
                    return (
                      <MenuItem onClick={(e) => {
                        popupState.close(e);
                        this.addTag(tag.id);
                      }}
                      >{tag.name}
                      </MenuItem>
                    );
                  }

                  return null;
                })}
              </Menu>
            </>
          )}
        </PopupState>
      </div>
    );
  }

  submit = () => {
    const newData = { ...this.state };
    newData.nutrition_facts = JSON.parse(this.state.nutrition_facts);

    if (this.props.add) {
      this.props.createProduct(newData);
    } else {
      this.props.updateProduct(newData);
    }

    this.close();
  }

  delete = () => {
    this.props.deleteProduct(this.state.id);
    this.close();
  }

  close = () => {
    this.setState({
      id: (this.props.product) ? this.props.product._id : '',
      name: (this.props.product) ? this.props.product.name : '',
      description: (this.props.product) ? this.props.product.description : '',
      tags: (this.props.product) ? this.props.product.tags : [],
      ingredients: (this.props.product) ? this.props.product.ingredients : [],
      nutrition_facts: (this.props.product) ? JSON.stringify(this.props.product.nutrition_facts) : JSON.stringify({}),
      private_fridge_exclude: (this.props.product) ? this.props.product.private_fridge_exclude : [],
      gluten_free: (this.props.product) ? this.props.product.gluten_free : false,
      vegan: (this.props.product) ? this.props.product.vegan : false,
      vegetarian: (this.props.product) ? this.props.product.vegetarian : false,
      product_type: (this.props.product) ? this.props.product.product_type : '',
      category: (this.props.product) ? this.props.product.category : '',
      brand: (this.props.product) ? this.props.product.brand : '',
      sku: (this.props.product) ? this.props.product.sku : '',
      price: (this.props.product && this.props.product.price) ? this.props.product.price.toFixed(2) : null,
      shelf_life: (this.props.product) ? this.props.product.shelf_life : null,
      image_id: (this.props.product) ? this.props.product.image_id : null,
      visible: (this.props.product) ? this.props.product.visible : null,
    });

    this.props.handleClose();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if (props.product) {
      this.setState({
        id: props.product._id,
        name: props.product.name,
        description: props.product.description,
        tags: props.product.tags,
        ingredients: props.product.ingredients,
        nutrition_facts: JSON.stringify(props.product.nutrition_facts),
        private_fridge_exclude: props.product.private_fridge_exclude,
        gluten_free: props.product.gluten_free,
        vegan: props.product.vegan,
        vegetarian: props.product.vegetarian,
        product_type: props.product.product_type,
        category: props.product.category,
        brand: props.product.brand,
        sku: props.product.sku,
        price: props.product.price,
        shelf_life: props.product.shelf_life,
        image_id: props.product.image_id,
        visible: props.product.visible,
      });
    }
  }

  render() {
    if (this.props.product || this.props.add) {
      return (
        <div>
          <Dialog open onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{this.props.product ? this.props.product.name : 'Create Product'}</DialogTitle>
            <DialogContent style={{ marginTop: '-15px', width: '450px' }}>
              <DialogContentText>
                Edit details about this product below
              </DialogContentText>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={this.state.visible} />}
                  label="Visible"
                  onChange={e => this.setState({ visible: e.target.checked })}
                />
              </FormGroup>
              <TextField
                margin="dense"
                label="Product id"
                defaultValue={this.state.id}
                fullWidth
                disabled
              />
              <TextField
                required
                margin="dense"
                label="Product name"
                defaultValue={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Product price"
                defaultValue={this.state.price}
                onChange={e => this.setState({ price: parseFloat(e.target.value) })}
                fullWidth
              />
              <TextField
                margin="dense"
                label="Product description"
                defaultValue={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
                fullWidth
              />
              {/* <TextField
                required
                margin="dense"
                label="Product type"
                defaultValue={this.state.product_type}
                onChange={e => this.setState({ product_type: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Product category"
                defaultValue={this.state.product_category}
                onChange={e => this.setState({ product_category: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Product brand"
                defaultValue={this.state.brand}
                onChange={e => this.setState({ brand: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Product SKU"
                defaultValue={this.state.sku}
                onChange={e => this.setState({ sku: e.target.value })}
                fullWidth
              /> */}
              <TextField
                margin="dense"
                label="Product ingredients, separated by commas"
                defaultValue={this.arrayToString(this.state.ingredients)}
                onChange={e => this.stringToArray(e.target.value)}
                fullWidth
              />
              <TextField
                margin="dense"
                label="Product image ID"
                defaultValue={this.state.image_id}
                onChange={e => this.setState({ image_id: e.target.value })}
                fullWidth
              />
              <TextField
                margin="dense"
                label="Nutrient info, in JSON format"
                defaultValue={this.state.nutrition_facts.replace('\\', '').split(',').join('\n')}
                onChange={e => this.setState({ nutrition_facts: e.target.value.replace(/(\r\n|\n|\r)/gm, ',') })}
                fullWidth
                multiline
              />
              <br />
              <div id="checkbox-row">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.gluten_free} />}
                    label="Gluten-free"
                    onChange={e => this.setState({ gluten_free: e.target.checked })}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.vegan} />}
                    label="Vegan"
                    onChange={e => this.setState({ vegan: e.target.checked })}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.vegetarian} />}
                    label="Vegetarian"
                    onChange={e => this.setState({ vegetarian: e.target.checked })}
                  />
                </FormGroup>
              </div>
              {this.renderTags()}
              {this.renderAddTag()}
              {this.renderPrivateCompanies()}
              {this.renderAddPrivateCompany()}
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Button variant="outlined"
                  color="primary"
                  onClick={this.submit}
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                >
                  Submit
                </Button>
                {this.props.deleteProduct
                  ? (
                    <Button variant="outlined"
                      color="primary"
                      onClick={this.delete}
                      style={{ marginLeft: '5px', marginRight: '5px' }}
                    >
                      Delete
                    </Button>
                  ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.close} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    fridges: state.fridges.fridges,
    companies: state.companies.companies,
    tags: state.tags.ourTags,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ViewProduct);
