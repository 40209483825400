import { connect } from 'react-redux';

import POSDevices from './component';

const mapStateToProps = (state) => {
  const {
    posDevices: {
      devices,
    },
    fridges: {
      fridges,
    },
  } = state;

  return {
    devices,
    fridges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(POSDevices);
