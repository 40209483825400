/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import InventoryPreview from './inventory-preview';
import InventoryView from './inventory-view';

import '../../../styles/companies.scss';

class Inventories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fridgeId: undefined,
    };
  }

  handleFridgeClick = (fridgeId) => {
    this.setState(() => ({
      fridgeId,
    }));
  };

  renderInventories = () => {
    this.props.fridges.sort((a, b) => ((a.name > b.name) ? 1 : -1));

    return this.props.fridges.map((fridge) => {
      return (
        <InventoryPreview
          id={fridge._id}
          name={fridge.name}
          productMap={this.props.productMap || {}}
          handleClick={this.handleFridgeClick}
        />
      );
    });
  };

  handleModalClose = () => {
    this.setState({
      fridgeId: undefined,
    });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className="paper-container">
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>POS Fridge Inventory</b>
              <div id="private-company-container">
                {this.renderInventories()}
              </div>
            </Typography>
          </Paper>
        </Grid>

        <InventoryView
          id={this.state.fridgeId}
          name={this.state.fridge ? this.state.fridge.name : ''}
          fridges={this.props.fridges}
          products={this.props.products}
          productMap={this.props.productMap || {}}
          handleInventoryUpdate={(inventory) => {
            if (this.state.fridgeId) {
              this.props.updateFridgeInventory(this.state.fridgeId, Object.entries(inventory).reduce((accum, [k, v]) => ([...accum, { productId: k, ...v }]), []));
              this.handleModalClose();
            } else console.warn('Fridge ID is undefined, not updating database...');
          }}
          onClose={this.handleModalClose}
        />
      </Grid>
    );
  }
}

export default Inventories;
