// the starting point for your redux store
// this defines what your store state will look like
import { combineReducers } from 'redux';

import AppReducer from './app-reducer';
import AdminReducer from './admin-reducer';
import FridgeReducer from './fridge-reducer';
import PromotionReducer from './promotion-reducer';
import UsersReducer from './users-reducer';
import OrdersReducer from './order-reducer';
import posDeviceReducer from './pos-device-reducer';
import ProductReducer from './product-reducer';
import CompanyReducer from './company-reducer';
import SettingsReducer from './settings-reducer';
import TagReducer from './tag-reducer';

const rootReducer = combineReducers({
  app: AppReducer,
  admin: AdminReducer,
  fridges: FridgeReducer,
  promotions: PromotionReducer,
  users: UsersReducer,
  orders: OrdersReducer,
  posDevices: posDeviceReducer,
  products: ProductReducer,
  companies: CompanyReducer,
  settings: SettingsReducer,
  tags: TagReducer,
});

export default rootReducer;
