import { ActionTypes } from '../actions';

// initial state
const initialState = {
  ourTags: [],
};

const TagReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_6AM_TAGS:
      return { ...state, ourTags: action.payload };
    default:
      return state;
  }
};

export default TagReducer;
