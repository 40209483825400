import React from 'react';
import {
  // eslint-disable-next-line no-unused-vars
  withRouter, BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import * as firebase from 'firebase';
import {
  fetchRemoteAppConfig, getAllFridges, authAdminUser, getAllPromotions, getAllUsers,
  get6AMTags, getAllOrders, getCurrentPOSPromotions, getCurrentMobilePromotions, getAllPrivateCompanies, getAllProducts, getAllSettings,
  fetchAllPOSDevices,
} from './state/actions';
import {
  Home, Terms, Privacy, Dashboard, requireAuth,
} from './components';

import { AppleOAuth, FacebookOAuth, GoogleOAuth } from './components/oauth';
import { TAB_PATHS } from './constants';

class App extends React.Component {
  componentDidMount() {
    this.props.fetchRemoteAppConfig();

    firebase.auth().onAuthStateChanged((user) => {
      // log in if auth persisted from previous session
      if (user) {
        firebase.auth().currentUser.getIdToken(true)
          .then((idToken) => {
            this.props.fetchRemoteAppConfig()
              .then(() => {
                this.props.authAdminUser(idToken).then(() => {
                  // load data
                  this.props.getAllFridges();
                  this.props.getAllPromotions();
                  this.props.getCurrentPOSPromotions();
                  this.props.getCurrentMobilePromotions();
                  this.props.getAllUsers();
                  this.props.get6AMTags();
                  this.props.getAllOrders();
                  this.props.getAllProducts();
                  this.props.getAllPrivateCompanies();
                  this.props.getAllProducts();
                  this.props.getAllSettings();
                  this.props.fetchAllPOSDevices();

                  if (this.props.location.pathname === '/') {
                    this.props.history.push('/orders');
                  }
                });
              })
              .catch((error) => {
                console.log(error);
              });
          });
      }
    });
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />

        <Route exact path={TAB_PATHS.FRIDGES} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.PROMOTIONS} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.USERS} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.ORDERS} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.POS_SALES} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.POS_DEVICES} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.POS_DOWNLOADS} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.PRODUCTS} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.COMPANIES} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.NOTIFICATIONS} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.SETTINGS} component={requireAuth(Dashboard, '/')} />
        <Route exact path={TAB_PATHS.INVENTORY} component={requireAuth(Dashboard, '/')} />

        <Route exact path="/tags" component={requireAuth(Dashboard, '/')} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />

        <Route exact path="/oauth/google" component={GoogleOAuth} />
        <Route exact path="/oauth/facebook" component={FacebookOAuth} />
        <Route exact path="/oauth/apple" component={AppleOAuth} />
        <Route component={Home} />
      </Switch>
    );
  }
}

export default withRouter(connect(null, {
  fetchRemoteAppConfig,
  getAllFridges,
  authAdminUser,
  getAllPromotions,
  getCurrentPOSPromotions,
  getCurrentMobilePromotions,
  getAllUsers,
  get6AMTags,
  getAllOrders,
  getAllProducts,
  getAllPrivateCompanies,
  getAllSettings,
  fetchAllPOSDevices,
})(App));
