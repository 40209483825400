import getRemoteAppConfig from '../../services/app-requests';

// keys for actiontypes
const ActionTypes = {
  SET_REMOTE_APP_CONFIG: 'SET_REMOTE_APP_CONFIG',
};

const fetchRemoteAppConfig = () => {
  return async (dispatch) => {
    const data = await getRemoteAppConfig();
    dispatch({ type: ActionTypes.SET_REMOTE_APP_CONFIG, payload: data });
  };
};

export {
  ActionTypes,
  fetchRemoteAppConfig,
};
