/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import '../../../styles/companies.scss';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import FridgePreview from './fridge_preview';
import ViewFridge from './view_fridge';
import { createFridge, updateFridge, deleteFridge } from '../../../state/actions';

class Fridges extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fridge: null,
      add: false,
    };
  }

  handleFridgeClick = (fridge) => {
    this.setState({
      fridge,
    });
  };

  renderFridges = (canOrderAhead) => {
    this.props.fridges.sort((a, b) => ((a.name > b.name) ? 1 : -1));

    return this.props.fridges.filter(fridge => fridge.is_public === !canOrderAhead).map((fridge) => {
      return <FridgePreview fridge={fridge} handleClick={this.handleFridgeClick} imgURL={`https://drive.google.com/uc?id=${fridge.image_id}`} />;
    });
  };

  handleModalClose = () => {
    this.setState({
      fridge: null,
      add: false,
    });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className="paper-container">
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>Order Ahead Fridges</b>
              <div id="private-company-container">
                {this.renderFridges(true)}
              </div>
            </Typography>
          </Paper>
          <Paper className="paper-container">
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>No Order Ahead Fridges</b>
              <div id="private-company-container">
                {this.renderFridges(false)}
              </div>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={this.props.fixedHeightPaperModal}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>Add Fridge</b>
            </Typography>
            <Fab variant="extended"
              aria-label="like"
              style={{
                color: 'white', fontFamily: 'avenir', fontWeight: 'bold', backgroundColor: '#008037',
              }}
              onClick={() => { this.setState({ add: true }); }}
            >
              Add Fridge
            </Fab>
          </Paper>
        </Grid>

        <ViewFridge
          fridge={this.state.fridge}
          handleClose={this.handleModalClose}
          updateFridge={this.props.updateFridge}
          deleteFridge={this.props.deleteFridge}
        />

        <ViewFridge
          handleClose={this.handleModalClose}
          createFridge={this.props.createFridge}
          add={this.state.add}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fridges: state.fridges.fridges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createFridge: (fields) => {
      dispatch(createFridge(fields));
    },
    updateFridge: (fields) => {
      dispatch(updateFridge(fields));
    },
    deleteFridge: (id) => {
      dispatch(deleteFridge(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Fridges);
