/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import '../../../styles/companies.scss';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import CompanyPreview from './company-preview';
import ViewCompany from './view-company';
import { createPrivateCompany, updatePrivateCompany, deletePrivateCompany } from '../../../state/actions';

class Companies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: null,
      add: false,
    };
  }

  handleCompanyClick = (company) => {
    this.setState({
      company,
    });
  };

  renderPrivateCompanies = () => {
    this.props.companies.sort((a, b) => ((a.name > b.name) ? 1 : -1));

    return this.props.companies.map((company) => {
      return <CompanyPreview company={company} handleClick={this.handleCompanyClick} />;
    });
  };

  createCompany = (fields) => {
    this.props.createPrivateCompany(fields);
  }

  updateCompany = (fields) => {
    this.props.updatePrivateCompany(fields);
  }

  deleteCompany = (id) => {
    this.props.deletePrivateCompany(id);
  }

  handleModalClose = () => {
    this.setState({
      company: null,
      add: false,
    });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className="paper-container">
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>Private Companies</b>
              <div id="private-company-container">
                {this.renderPrivateCompanies()}
              </div>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={this.props.fixedHeightPaperModal}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>Add New Company</b>
            </Typography>
            <Fab variant="extended"
              aria-label="like"
              style={{
                color: 'white', fontFamily: 'avenir', fontWeight: 'bold', backgroundColor: '#008037',
              }}
              onClick={() => { this.setState({ add: true }); }}
            >
              Add New Company
            </Fab>
          </Paper>
        </Grid>

        <ViewCompany
          company={this.state.company}
          handleClose={this.handleModalClose}
          updateCompany={this.updateCompany}
          deleteCompany={this.deleteCompany}
        />

        <ViewCompany
          handleClose={this.handleModalClose}
          createCompany={this.createCompany}
          add={this.state.add}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companies.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPrivateCompany: (fields) => {
      dispatch(createPrivateCompany(fields));
    },
    updatePrivateCompany: (fields) => {
      dispatch(updatePrivateCompany(fields));
    },
    deletePrivateCompany: (id) => {
      dispatch(deletePrivateCompany(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Companies);
