/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import LoadingIcon from './loading-icon';

const LoadingRedirect = (props) => {
  const { redirect } = props;
  const [content, setContent] = useState(<LoadingIcon />);

  useEffect(() => {
    setTimeout(() => {
      setContent(<Redirect to={redirect} />);
    }, 1500);
  }, []);

  return content;
};

export default function requireAuthHelper(Comp, redirect) {
  const requireAuth = (props) => {
    return (props.authToken !== null)
      ? <Comp {...props} />
      : <LoadingRedirect redirect={redirect} />;
  };

  const mapStateToProps = state => ({
    authToken: state.admin.authToken,
  });

  return withRouter(connect(mapStateToProps, null)(requireAuth));
}
