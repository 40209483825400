import axios from 'axios';
import * as firebase from 'firebase';

const getAllPrivateCompanies = async () => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.get(`${global.API_URL}/companies`, { headers: { authorization: idToken } });
  return response.data;
};

const createPrivateCompany = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = axios.post(`${global.API_URL}/companies`, { authToken: idToken, fields });
  return response.data;
};

const getPrivateCompany = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.get(`${global.API_URL}/companies/${id}`, { headers: { authorization: idToken } });
  return response.data;
};

const updatePrivateCompany = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/companies/${fields.id}`, { authToken: idToken, fields });
  return response.data;
};

const deletePrivateCompany = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.delete(`${global.API_URL}/companies/${id}`, { headers: { authorization: idToken } });
  return response.data;
};

const getFridgesForPrivateCompany = async (companyId) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.get(`${global.API_URL}/companies/fridges/${companyId}`, { headers: { authorization: idToken } });
  return response.data;
};

export {
  getAllPrivateCompanies,
  getPrivateCompany,
  createPrivateCompany,
  updatePrivateCompany,
  deletePrivateCompany,
  getFridgesForPrivateCompany,
};
