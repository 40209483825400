import { ActionTypes } from '../actions';

const initialState = {
  products: [],
  companyProducts: [],
  fridgeProducts: [],
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_PRODUCTS:
      return { ...state, products: action.payload };
    case ActionTypes.GET_PRODUCTS_FOR_COMPANY:
      return { ...state, companyProducts: action.payload };
    case ActionTypes.GET_PRODUCTS_FOR_PRIVATE_FRIDGE:
      return { ...state, fridgeProducts: action.payload };
    default:
      return state;
  }
};

export default ProductReducer;
