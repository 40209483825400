import { ActionTypes } from '../actions';

// initial state
const initialState = {
  orders: [],
};

const OrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_ORDERS:
      return { ...state, orders: action.payload };
    default:
      return state;
  }
};

export default OrdersReducer;
