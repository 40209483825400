/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import PrintIcon from '@material-ui/icons/Print';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { TABLE_ICONS } from '../../../constants';

import ViewOrder from './view-order';
import { closeOrder } from '../../../state/actions';

import '../../../styles/orders.scss';

const columns = [
  { title: 'Order ID', field: 'id' },
  { title: 'User', field: 'user_id' },
  { title: 'Fridge', field: 'fridge_id' },
  { title: 'Items', field: 'num_items', type: 'numeric' },
  { title: 'Subtotal', field: 'subtotal' },
  { title: 'Total', field: 'total' },
  { title: 'Payment', field: 'payment_method' },
  { title: 'Opened', field: 'time_opened' },
  { title: 'Delivery', field: 'scheduled_delivery_date' },
];

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentOrder: null,
      activeOrdersFiltering: false,
      closedOrdersFiltering: false,
      ordersToPrint: [],
    };

    this.printRef = React.createRef();
    this.buttonRef = React.createRef();
  }

  listActiveOrders = () => {
    return (
      <MaterialTable
        title="Active Orders"
        icons={TABLE_ICONS}
        columns={columns}
        data={this.generateRowData(true)}
        onRowClick={this.handleOrderClick}
        options={{
          exportButton: true,
          selection: true,
          filtering: this.state.activeOrdersFiltering,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 75, 100],
        }}
        actions={[
          {
            tooltip: 'Print all selected orders',
            icon: PrintIcon,
            onClick: this.printMultipleOrders,
          },
          {
            tooltip: 'Close all selected orders',
            icon: CheckCircleIcon,
            onClick: this.closeMultipleOrders,
          },
          {
            icon: FilterList,
            tooltip: 'Filter columns',
            isFreeAction: true,
            onClick: () => {
              const currentValue = this.state.activeOrdersFiltering;
              this.setState({
                activeOrdersFiltering: !currentValue,
              });
            },
          },
        ]}
      />
    );
  }

  listClosedOrders = () => {
    return (
      <MaterialTable
        title="Closed Orders"
        icons={TABLE_ICONS}
        columns={columns}
        data={this.generateRowData(false)}
        onRowClick={this.handleOrderClick}
        options={{
          exportButton: true,
          selection: true,
          filtering: this.state.closedOrdersFiltering,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 75, 100],
        }}
        actions={[
          {
            tooltip: 'Print all selected orders',
            icon: PrintIcon,
            onClick: this.printMultipleOrders,
          },
          {
            icon: FilterList,
            tooltip: 'Filter columns',
            isFreeAction: true,
            onClick: () => {
              const currentValue = this.state.closedOrdersFiltering;
              this.setState({
                closedOrdersFiltering: !currentValue,
              });
            },
          },
        ]}
      />
    );
  }

  handleOrderClick = (event, data) => {
    const foundOrder = this.props.orders.find(order => order.id === data.id);

    if (foundOrder) {
      foundOrder.user_name = this.getUserName(foundOrder.user_id);
      foundOrder.fridge_name = this.getFridgeName(foundOrder.fridge_id);
      foundOrder.delivery_date_string = this.formatDate(new Date(foundOrder.scheduled_delivery_date));

      this.setState({
        currentOrder: foundOrder,
      });
    }
  }

  handleModalClose = () => {
    this.setState({
      currentOrder: null,
    });
  }

  generateRowData = (openOrders) => {
    const orders = openOrders
      ? this.props.orders.filter(order => order.is_open && order.is_private)
      : this.props.orders.filter(order => !order.is_open && order.is_private);

    return orders.map((order) => {
      return {
        id: order._id,
        user_id: this.getUserName(order.user_id),
        fridge_id: this.getFridgeName(order.fridge_id),
        num_items: Object.keys(order.items).length,
        subtotal: order.subtotal ? `$${order.subtotal.toFixed(2)}` : '$0',
        total: order.total ? `$${order.total.toFixed(2)}` : '$0',
        payment_method: order.payment_method,
        time_opened: this.formatDate(new Date(order.time_opened)),
        scheduled_delivery_date: this.formatDate(new Date(order.scheduled_delivery_date)),
      };
    });
  }

  printMultipleOrders = (event, data) => {
    const ordersToPrint = [];

    data.forEach((item) => {
      const foundOrder = this.props.orders.find(order => order.id === item.id);

      if (foundOrder) {
        foundOrder.user_name = this.getUserName(foundOrder.user_id);
        foundOrder.fridge_name = this.getFridgeName(foundOrder.fridge_id);
        foundOrder.delivery_date_string = this.formatDate(new Date(foundOrder.scheduled_delivery_date));

        ordersToPrint.push(foundOrder);
      }
    });

    this.setState({
      ordersToPrint,
    }, () => {
      this.buttonRef.click();
    });
  }

  closeMultipleOrders = (event, data) => {
    data.forEach((order) => {
      this.props.closeOrder(order.id);
    });
  }

  getUserName = (id) => {
    const foundUser = this.props.users.find((user) => {
      return user.id === id;
    });

    if (foundUser) {
      return `${foundUser.first_name} ${foundUser.last_name}`;
    } else {
      return '';
    }
  }

  getFridgeName = (id) => {
    const foundFridge = this.props.fridges.find((fridge) => {
      return fridge.id === id;
    });

    if (foundFridge) {
      return foundFridge.name;
    } else {
      return '';
    }
  }

  formatDate = (date) => {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : `0${month}`;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;

    return `${month}/${day}/${year}`;
  }

  renderAllItemsToPrint = () => {
    const output = [];

    this.state.ordersToPrint.forEach((order) => {
      this.renderProductPrintImages(order).forEach((item) => {
        output.push(item);
      });
    });

    return output;
  }

  renderProductPrintImages = (order) => {
    const output = [];

    let totalCount = 0;

    Object.keys(order.items).forEach((productId) => {
      totalCount += order.items[productId].count;
    });

    Object.keys(order.items).forEach((productId) => {
      const item = order.items[productId];
      const productName = item.productInfo.name;
      const image = (item.productInfo.image_id ? (
        <img className="product-image"
          src={`https://drive.google.com/uc?export=view&id=${item.productInfo.image_id}`}
          alt={productName}
        />
      ) : <div className="no-image" />);

      for (let i = 1; i <= item.count; i += 1) {
        output.push((
          <div className="print-label">
            <div>
              <h2 className="print-label-user">{order.user_name}</h2>
            </div>
            <div id="product-image-area">
              <div className="product-area">
                {image}
                <div className="product-text-area">
                  <p>{item.productInfo.name}</p>
                  <p>{`${i} of ${item.count}`}</p>
                  <p className="product-text-no-top">{`Items in order: ${totalCount}`}</p>
                </div>
              </div>
            </div>
            <p className="product-text-delivery-date">{`Delivered on: ${order.delivery_date_string}`}</p>
          </div>
        ));
      }
    });

    return output;
  };

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={15} md={11} lg={12}>
          {this.listActiveOrders()}
        </Grid>
        <Grid item xs={15} md={11} lg={12}>
          {this.listClosedOrders()}
        </Grid>

        <ReactToPrint
          trigger={() => (
            <input ref={(input) => { this.buttonRef = input; }} />
          )}
          content={() => this.printRef.current}
        />

        {/* render the label to be printed */}
        <div id="print-label-container">
          <div id="print-label-items" ref={this.printRef}>
            {this.renderAllItemsToPrint()}
          </div>
        </div>

        <ViewOrder
          order={this.state.currentOrder}
          handleClose={this.handleModalClose}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders.orders,
    fridges: state.fridges.fridges,
    users: state.users.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeOrder: (id) => {
      dispatch(closeOrder(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Orders);
