/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import '../../../styles/products.scss';

export default (props) => {
  const productName = props.product.name;
  const image = (props.product.image_id ? <img className="productImage" src={`https://drive.google.com/uc?export=view&id=${props.product.image_id}`} alt={productName} /> : <div className="no-image" />);
  const price = (props.product.price ? '$' + props.product.price.toFixed(2) : 'no price');
  return (
    <div className="product-preview-area" onClick={() => { props.handleClick(props.product); }}>
      <h5 className="name">{productName}</h5>
      {image}
      <p className="price">{price}</p>
    </div>
  );
};
