/* eslint-disable no-param-reassign */
import * as productRequests from '../../services/product-requests';

// keys for actiontypes
const ActionTypes = {
  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
  GET_PRODUCTS_FOR_COMPANY: 'GET_PRODUCTS_FOR_COMPANY',
  GET_PRODUCTS_FOR_PRIVATE_FRIDGE: 'GET_PRODUCTS_FOR_PRIVATE_FRIDGE',
};

const getAllProducts = () => {
  return async (dispatch) => {
    const data = await productRequests.getAllProducts();
    dispatch({ type: ActionTypes.GET_ALL_PRODUCTS, payload: data });
  };
};

const createProduct = (fields) => {
  return async (dispatch) => {
    fields.display_template = 'basic';
    await productRequests.createProduct(fields);
    const data = await productRequests.getAllProducts();
    dispatch({ type: ActionTypes.GET_ALL_PRODUCTS, payload: data });
  };
};

const updateProduct = (id, fields) => {
  return async (dispatch) => {
    await productRequests.updateProduct(id, fields);
    const data = await productRequests.getAllProducts();
    dispatch({ type: ActionTypes.GET_ALL_PRODUCTS, payload: data });
  };
};

const deleteProduct = (id) => {
  return async (dispatch) => {
    await productRequests.deleteProduct(id);
    const data = await productRequests.getAllProducts();
    dispatch({ type: ActionTypes.GET_ALL_PRODUCTS, payload: data });
  };
};

export {
  ActionTypes,
  getAllProducts,
  createProduct,
  updateProduct,
  deleteProduct,
};
