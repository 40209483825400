import { ActionTypes } from '../actions';

// initial state
const initialState = {
  authToken: null,
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ADMIN_AUTHID:
      return {
        authToken: action.payload,
      };
    case ActionTypes.DEAUTH:
      return {
        initialState,
      };
    default:
      return state;
  }
};

export default AdminReducer;
