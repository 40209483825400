import React from 'react';
import Loader from 'react-loader-spinner';

const LoadingIcon = () => {
  return (
    <div id="loading-icon">
      <h3>Loading</h3>
      <Loader type="ThreeDots" color="#008037" height="100" width="100" />
      <style jsx>{`
        #loading-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100vw;
            height: 100vh;
        }

        h3 {
            margin-bottom: 0px;
        }
    `}</style>
    </div>
  );
};

export default LoadingIcon;
