/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import DateFnsUtils from '@date-io/date-fns';
import Edit from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import Delete from '@material-ui/icons/Delete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { connect } from 'react-redux';
import '../../../styles/promotion_box.scss';
import { createPromotion, updatePromotion, deletePromotion } from '../../../state/actions';

class AddPromotionWindow extends React.Component {
  constructor(props) {
    super(props);
    this.TOKEN_PATH = 'token.json';
    this.SCOPES = ['https://www.googleapis.com/auth/drive.metadata.readonly'];

    this.state = {
      title: (this.props.promotion) ? this.props.promotion.display_content.title : '',
      subtitle: (this.props.promotion) ? this.props.promotion.display_content.subtitle : '',
      imageID: (this.props.promotion) ? this.props.promotion.display_content.imageID : '',
      startDate: (this.props.promotion) ? new Date(this.props.promotion.start_date) : new Date(),
      endDate: (this.props.promotion) ? new Date(this.props.promotion.end_date) : new Date(),
      order: (this.props.promotion) ? this.props.promotion.order : 0,
      associated_product_id: (this.props.promotion) ? this.props.promotion.associated_product_id : '',
      deleteDialog: false,
      open: false,
      hasStartDate: (this.props.promotion) ? (this.props.promotion.start_date !== null) : true,
      hasEndDate: (this.props.promotion) ? (this.props.promotion.end_date !== null) : true,
      pos_app: this.props.promotion ? this.props.promotion.pos_app : false,
      mobile_app: this.props.promotion ? this.props.promotion.mobile_app : false,
    };
  }

handleClickOpen = () => {
  this.setState({ open: true });
};

handleClose = () => {
  this.setState({ open: false });
};

openDeleteDialog = () => {
  this.setState({ deleteDialog: true });
}

closeDeleteDialog = () => {
  this.setState({ deleteDialog: false });
}

handleDeletePromotion = () => {
  this.props.deletePromotion(this.props.promotion._id);
  this.setState({ deleteDialog: false });
}

deletePromotion = () => {
  return (
    <Dialog
      open={this.state.deleteDialog}
      onClose={this.closeDeleteDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete this promotion.</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this promotion?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleDeletePromotion} color="primary">
          Yes
        </Button>
        <Button onClick={this.closeDeleteDialog} color="primary" autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

addPromotion = () => {
  this.props.createPromotion({
    display_template: 'basic',
    display_content: {
      title: this.state.title,
      subtitle: this.state.subtitle,
      imageID: this.state.imageID,
    },
    start_date: this.state.hasStartDate ? this.state.startDate : null,
    end_date: this.state.hasEndDate ? this.state.endDate : null,
    order: this.state.order,
    associated_product_id: this.state.associated_product_id,
    pos_app: this.state.pos_app,
    mobile_app: this.state.mobile_app,
  });

  this.setState({ open: false });
}

updatePromotion = () => {
  this.props.updatePromotion({
    display_template: 'basic',
    display_content: {
      title: this.state.title,
      subtitle: this.state.subtitle,
      imageID: this.state.imageID,
    },
    start_date: this.state.hasStartDate ? this.state.startDate : null,
    end_date: this.state.hasEndDate ? this.state.endDate : null,
    order: this.state.order,
    id: this.props.promotion.id,
    associated_product_id: this.state.associated_product_id,
    pos_app: this.state.pos_app,
    mobile_app: this.state.mobile_app,
  });

  this.setState({ open: false });
}

setDate = (value) => {
  const end = new Date();
  end.setDate(this.state.startDate.getDate() + value);
  this.setState({ duration: value, endDate: end });
}

// eslint-disable-next-line camelcase
UNSAFE_componentWillReceiveProps(props) {
  if (props.promotion) {
    this.setState({
      title: (props.promotion) ? props.promotion.display_content.title : '',
      subtitle: (props.promotion) ? props.promotion.display_content.subtitle : '',
      imageID: (props.promotion) ? props.promotion.display_content.imageID : '',
      startDate: (props.promotion) ? new Date(props.promotion.start_date) : new Date(),
      endDate: (props.promotion) ? new Date(props.promotion.end_date) : new Date(),
      order: (props.promotion) ? props.promotion.order : 0,
      associated_product_id: (props.promotion) ? props.promotion.associated_product_id : '',
      pos_app: (props.promotion) ? props.promotion.pos_app : false,
      mobile_app: (props.promotion) ? props.promotion.mobile_app : false,
    });
  }
}

render() {
  return (
    <div>
      { (this.props.type === 'add')
            && (
            <Fab variant="extended"
              aria-label="like"
              style={{
                color: 'white', fontFamily: 'avenir', fontWeight: 'bold', backgroundColor: '#008037',
              }}
              onClick={this.handleClickOpen}
            >
              {this.props.messages.buttonText}
            </Fab>
            )}
      { (this.props.type === 'modify_promotions')
            && (
            <div>
              <Button variant="outlined" style={{ marginRight: 15, fontFamily: 'avenir', color: '#008037' }} onClick={this.handleClickOpen}>
                <Edit />
              </Button>
              <Button variant="outlined" style={{ fontFamily: 'avenir', color: '#008037' }} onClick={this.openDeleteDialog}>
                <Delete />
              </Button>
            </div>
            )}
      {this.deletePromotion()}
      <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{this.props.messages.titleText}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.messages.dialogueText}
          </DialogContentText>
          <TextField
            required
            margin="dense"
            label="Promotion Title"
            value={this.state.title}
            onChange={e => this.setState({ title: e.target.value })}
            fullWidth
          />
          <TextField
            required
            margin="dense"
            label="Promotion Subtitle"
            value={this.state.subtitle}
            onChange={e => this.setState({ subtitle: e.target.value })}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Image ID"
            value={this.state.imageID}
            onChange={e => this.setState({ imageID: e.target.value })}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Sort Order (low to high)"
            value={this.state.order}
            onChange={e => this.setState({ order: e.target.value })}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Associated Product ID"
            value={this.state.associated_product_id}
            onChange={e => this.setState({ associated_product_id: e.target.value })}
            fullWidth
          />
          <br /><br />
          <div className="date-selector-area">
            <div className="date-selector">
              <Checkbox
                checked={this.state.hasStartDate}
                onChange={() => { this.setState({ hasStartDate: !this.state.hasStartDate }); }}
                value="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled={!this.state.hasStartDate}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline-1"
                label="Start date."
                value={this.state.startDate ? this.state.startDate : null}
                onChange={e => this.setState({ startDate: e })}
              />
            </MuiPickersUtilsProvider>
          </div>

          <br />
          <div className="date-selector-area">
            <div className="date-selector">
              <Checkbox
                checked={this.state.hasEndDate}
                onChange={() => { this.setState({ hasEndDate: !this.state.hasEndDate }); }}
                value="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled={!this.state.hasEndDate}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline-2"
                label="End date."
                value={this.state.endDate ? this.state.endDate : null}
                onChange={e => this.setState({ endDate: e })}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div id="toggle-button-container">
            <div className="promo-button">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={this.state.pos_app} onChange={() => this.setState({ pos_app: !this.state.pos_app })} value="this.state.pos_app" />}
                  label="POS App"
                />
              </FormGroup>
            </div>
            <div className="promo-button">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={this.state.mobile_app} onChange={() => this.setState({ mobile_app: !this.state.mobile_app })} value="this.state.mobile_app" />}
                  label="Mobile App"
                />
              </FormGroup>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <Button variant="outlined" color="primary" onClick={(this.props.promotion) ? this.updatePromotion : this.addPromotion}>
              Submit
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
}

const mapDispatchToProps = (dispatch) => {
  return {
    createPromotion: (promotion) => {
      dispatch(createPromotion(promotion));
    },
    updatePromotion: (fields) => {
      dispatch(updatePromotion(fields));
    },
    deletePromotion: (id) => {
      dispatch(deletePromotion(id));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(AddPromotionWindow);
