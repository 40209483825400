import * as orderRequests from '../../services/order-requests';

// keys for action types
const ActionTypes = {
  GET_ALL_ORDERS: 'GET_ALL_ORDERS',
};

const getAllOrders = () => {
  return async (dispatch) => {
    const data = await orderRequests.getAllOrders();
    dispatch({ type: ActionTypes.GET_ALL_ORDERS, payload: data });
  };
};

const closeOrder = (id) => {
  return async (dispatch) => {
    await orderRequests.closeOrder(id);
    const data = await orderRequests.getAllOrders();
    dispatch({ type: ActionTypes.GET_ALL_ORDERS, payload: data });
  };
};

const openOrder = (id) => {
  return async (dispatch) => {
    await orderRequests.openOrder(id);
    const data = await orderRequests.getAllOrders();
    dispatch({ type: ActionTypes.GET_ALL_ORDERS, payload: data });
  };
};

const updateOrder = (id, fields) => {
  return async (dispatch) => {
    await orderRequests.updateOrder(id, fields);
    const data = await orderRequests.getAllOrders();
    dispatch({ type: ActionTypes.GET_ALL_ORDERS, payload: data });
  };
};

const updatedScheduledDeliveryDate = (id, date) => {
  return async (dispatch) => {
    await orderRequests.updatedScheduledDeliveryDate(id, date);
    const data = await orderRequests.getAllOrders();
    dispatch({ type: ActionTypes.GET_ALL_ORDERS, payload: data });
  };
};

export {
  ActionTypes,
  getAllOrders,
  closeOrder,
  openOrder,
  updateOrder,
  updatedScheduledDeliveryDate,
};
