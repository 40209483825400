import * as fridgeRequests from '../../services/fridge-requests';

// keys for action types
const ActionTypes = {
  GET_ALL_FRIDGES: 'GET_ALL_FRIDGES',
};

const getAllFridges = () => {
  return async (dispatch) => {
    const data = await fridgeRequests.getAllFridges();
    dispatch({ type: ActionTypes.GET_ALL_FRIDGES, payload: data });
  };
};

const createFridge = (fields) => {
  return async (dispatch) => {
    await fridgeRequests.createFridge(fields);
    const data = await fridgeRequests.getAllFridges();
    dispatch({ type: ActionTypes.GET_ALL_FRIDGES, payload: data });
  };
};

const updateFridge = (fields) => {
  return async (dispatch) => {
    await fridgeRequests.updateFridge(fields);
    const data = await fridgeRequests.getAllFridges();
    dispatch({ type: ActionTypes.GET_ALL_FRIDGES, payload: data });
  };
};

const updateFridgeInventory = (fridgeId, updates, decrement = false) => {
  return async (dispatch) => {
    await fridgeRequests.updateFridgeInventory(fridgeId, updates, decrement);
    const data = await fridgeRequests.getAllFridges();
    dispatch({ type: ActionTypes.GET_ALL_FRIDGES, payload: data });
  };
};

const deleteFridge = (id) => {
  return async (dispatch) => {
    await fridgeRequests.deleteFridge(id);
    const data = fridgeRequests.getAllFridges();
    dispatch({ type: ActionTypes.GET_ALL_FRIDGES, payload: data });
  };
};

export {
  ActionTypes,
  getAllFridges,
  createFridge,
  updateFridge,
  updateFridgeInventory,
  deleteFridge,
};
