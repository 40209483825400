import axios from 'axios';
import * as firebase from 'firebase';

const getAllFridges = async () => {
  const response = await axios.get(`${global.API_URL}/fridges`);
  return response.data;
};

const createFridge = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = axios.post(`${global.API_URL}/fridges`, { authToken: idToken, fields });
  return response.data;
};

const updateFridge = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/fridges/${fields.id}`, { authToken: idToken, fields });
  return response.data;
};

const updateFridgeInventory = async (fridgeId, updates, decrement) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/fridges/inventory/${fridgeId}`, { updates, decrement }, { headers: { authorization: idToken } });
  return response.data;
};

const deleteFridge = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.delete(`${global.API_URL}/fridges/${id}`, { headers: { authorization: idToken } });
  return response.data;
};

export {
  getAllFridges,
  createFridge,
  updateFridge,
  updateFridgeInventory,
  deleteFridge,
};
