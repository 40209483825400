/* eslint-disable prefer-template */
import React from 'react';

class PromotionDisplay extends React.Component {
  delta = (start, end) => {
    const current = new Date();
    const date1 = new Date(start);
    const date2 = new Date(end);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (current > date2) {
      return 'Expired';
    }

    return (diffDays === 1) ? diffDays + ' day.' : diffDays + ' days.';
  }

  formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('/');
  }

  render() {
    const content = this.props.item.display_content;
    const start = this.props.item.start_date;
    const end = this.props.item.end_date;
    const metadata = this.props.item.promotion_content;

    const img = `https://drive.google.com/uc?export=view&id=${content.imageID}`;
    return (
      <div>
        <div className="text">
          <br />
          <h4 className="title">{content.title}</h4>
          <h5 className="subtitle">{content.subtitle}</h5>
        </div>
        <img src={img} className="promoImage" alt="Promotional" />
        <div className="promoMetadata">
          { (metadata) ? <p>Promotional Discount: <b>{metadata.percent * 100 }%</b></p> : '' }
          { (start != null ? <p>Start Date: <b>{ this.formatDate(start) }</b></p> : <p>Start Date: <b>null</b></p>) }
          { (end != null ? <p>End Date: <b>{ this.formatDate(end) }</b></p> : <p>End Date: <b>null</b></p>) }
        </div>
      </div>
    );
  }
}

export default PromotionDisplay;
