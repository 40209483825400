/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Edit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const DeviceView = ({
  onCreate, onUpdate, onDelete, onClose,
  id, name, fridge_id, fridges, passcode, add, handleFieldUpdate,
}) => {
  const renderFridgeIdSelector = () => {
    if (fridges.length === 0) {
      return null;
    }

    return (
      <div style={{
        display: 'flex', justifyContent: 'flex-start', marginTop: '20px', marginBottom: '20px',
      }}
      >
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                Set Fridge
              </Button>
              <Menu {...bindMenu(popupState)}>
                {fridges.map((fridge) => {
                  return (
                    <MenuItem onClick={(e) => {
                      popupState.close(e);
                      handleFieldUpdate('fridge_id', fridge.id);
                    }}
                    >{fridge.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </PopupState>
      </div>
    );
  };

  if (id || add) {
    return (
      <div>
        <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{id ? name : 'Create Private Device'}</DialogTitle>
          <DialogContent style={{ marginTop: '-15px', width: '450px' }}>
            <DialogContentText>
              Edit details about this device below
            </DialogContentText>

            <TextField
              required
              margin="dense"
              label="Device ID"
              defaultValue={id}
              onChange={e => handleFieldUpdate('id', e.target.value)}
              fullWidth
            />

            <TextField
              margin="dense"
              label="Device name"
              defaultValue={name}
              onChange={e => handleFieldUpdate('name', e.target.value)}
              fullWidth
            />

            <TextField
              margin="dense"
              label="Device Passcode"
              defaultValue={passcode}
              onChange={e => handleFieldUpdate('passcode', e.target.value)}
              fullWidth
            />

            <TextField
              margin="dense"
              label="Fridge"
              value={fridges.find(f => f.id === fridge_id) ? fridges.find(f => f.id === fridge_id).name : ''}
              fullWidth
              disabled
            />

            {renderFridgeIdSelector()}

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
              <Button variant="outlined"
                color="primary"
                onClick={add
                  ? () => {
                    onCreate({
                      id, name, fridge_id, passcode,
                    }); onClose();
                  }
                  : () => {
                    onUpdate({
                      id, name, fridge_id, passcode,
                    }); onClose();
                  }}
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >
                Submit
              </Button>
              {onDelete
                ? (
                  <Button variant="outlined"
                    color="primary"
                    onClick={() => { onDelete(id); onClose(); }}
                    style={{ marginLeft: '5px', marginRight: '5px' }}
                  >
                    Delete
                  </Button>
                ) : null}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return null;
};

export default DeviceView;
