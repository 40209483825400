import axios from 'axios';
import * as firebase from 'firebase';

const getAllProducts = async () => {
  const response = await axios.get(`${global.API_URL}/products`);
  return response.data;
};

const getProductsForCompany = async (companyID) => {
  const response = await axios.get(`${global.API_URL}/products/company/${companyID}`);
  return response.data;
};

const getProductsForPrivateFridge = async (fridgeID) => {
  const response = await axios.get(`${global.API_URL}/products/fridge/${fridgeID}`);
  return response.data;
};

const getProduct = async (id) => {
  const response = await axios.get(`${global.API_URL}/products/${id}`);
  return response.data;
};

const createProduct = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.post(`${global.API_URL}/products`, { authToken: idToken, fields });
  return response.data;
};

const updateProduct = async (id, fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/products/${id}`, { authToken: idToken, fields });
  return response.data;
};

const deleteProduct = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.delete(`${global.API_URL}/products/${id}`, { headers: { authorization: idToken } });
  return response.data;
};

export {
  getAllProducts,
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
  getProductsForCompany,
  getProductsForPrivateFridge,
};
