/* eslint-disable no-case-declarations */
import { ActionTypes } from '../actions';

const initialState = {
  companies: [],
  fridgesForPrivateCompanies: {},
};

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_COMPANIES:
      return { ...state, companies: action.payload };
    case ActionTypes.GET_FRIDGES_FOR_PRIVATE_COMPANY:
      const newFridgesForPrivateCompanies = { ...(state.fridgesForPrivateCompanies) };
      newFridgesForPrivateCompanies[action.payload.id] = action.payload.response;
      return { ...state, fridgesForPrivateCompanies: newFridgesForPrivateCompanies };
    default:
      return state;
  }
};

export default CompanyReducer;
