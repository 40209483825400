/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useState } from 'react';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { connect } from 'react-redux';

import {
  setUserPrivateCompanies, setUserPrivateFridges, setUserActiveDiscount, setUserAdmin, setUserStripeTestMode,
} from '../../../state/actions';
import ViewUser from './view-user';

const json2csv = require('json2csv');

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// adopted from: https://stackoverflow.com/questions/51215642/converting-object-into-json-and-downloading-as-a-json-file-in-react
const exportToJson = (objectData) => {
  const filename = 'user-data.json';
  const contentType = 'application/json;charset=utf-8;';
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    a.download = filename;
    a.href = `data:${contentType},${encodeURIComponent(JSON.stringify(objectData))}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

const exportToCSV = (objectData) => {
  const filename = 'user-data.csv';
  const contentType = 'text/csv;charset=utf-8;';
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([decodeURIComponent(encodeURI(objectData))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    a.download = filename;
    a.href = `data:${contentType},${encodeURIComponent(objectData)}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

const Users = (props) => {
  const [filtering, setFiltering] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const columns = [
    { title: 'First', field: 'first_name' },
    { title: 'Last', field: 'last_name' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone_number' },
    { title: 'Companies', field: 'companies' },
    { title: 'Private Fridges', field: 'private_fridges' },
    { title: 'Date Joined', field: 'date_joined' },
    { title: 'Birthday', field: 'birthday' },
    { title: 'Favorite Items', field: 'favorite_items' },
    { title: 'Zip Code', field: 'zip_code' },
    { title: 'id', field: 'uid' },
  ];

  const getCompanyName = (id) => {
    const foundCompany = props.companies.find((company) => {
      return company.id === id;
    });

    if (foundCompany) {
      return foundCompany.name;
    } else {
      return '';
    }
  };

  const getFridgeName = (id) => {
    const foundFridge = props.fridges.find((fridge) => {
      return fridge.id === id;
    });

    if (foundFridge) {
      return foundFridge.name;
    } else {
      return '';
    }
  };

  const getProductName = (id) => {
    const foundProduct = props.products.find((product) => {
      return product.id === id;
    });

    if (foundProduct) {
      return foundProduct.name;
    } else {
      return '';
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : `0${month}`;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;

    return `${month}/${day}/${year}`;
  };

  const formatUserCompanies = (companies) => {
    return companies.map((company) => {
      return getCompanyName(company);
    }).filter(item => item.length > 0).join(', ');
  };

  const formatUserPrivateFridges = (privateFridges) => {
    return privateFridges.map((fridge) => {
      return getFridgeName(fridge);
    }).filter(item => item.length > 0).join(', ');
  };

  const formatUserProducts = (products) => {
    return products.map((product) => {
      return getProductName(product);
    }).filter(item => item.length > 0).join(', ');
  };

  const getUserData = () => {
    return props.users.map((user) => {
      return {
        uid: user._id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        date_joined: formatDate(new Date(user.date_account_created)),
        num_active_orders: Object.keys(user.active_orders).length,
        num_closed_orders: Object.keys(user.closed_orders).length,
        phone_number: user.phone_number,
        birthday: formatDate(new Date(user.birthday)),
        companies: formatUserCompanies(user.private_companies),
        private_fridges: formatUserPrivateFridges(user.private_fridges),
        zip_code: user.zip_code,
        favorite_items: formatUserProducts(user.favorite_item_ids),
      };
    });
  };

  const handleRowClick = (event, data) => {
    const foundUser = props.users.find(user => user.id === data.uid);

    if (foundUser) {
      setSelectedUser(foundUser);
    }
  };

  const handleModalClose = () => {
    setSelectedUser(null);
  };

  const setUserCompanies = (uid, privateCompanies) => {
    props.setUserPrivateCompanies(uid, privateCompanies);
  };

  const setUserFridges = (uid, privateFridges) => {
    props.setUserPrivateFridges(uid, privateFridges);
  };

  const setUserDiscount = (uid, activeDiscount) => {
    props.setUserActiveDiscount(uid, activeDiscount);
  };

  const setUserAdminUser = (uid, bool) => {
    props.setUserAdmin(uid, bool);
  };

  const setUserStripeTestMode1 = (uid, bool) => {
    props.setUserStripeTestMode(uid, bool);
  };

  const exportCSV = (event, data) => {
    const csvHeaders = ['uid', 'first_name', 'last_name', 'email', 'date_joined', 'num_active_orders', 'num_closed_orders',
      'phone_number', 'birthday', 'companies', 'private_fridges', 'zip_code', 'favorite_items'];

    const newCSVData = [];

    // user selected specific columns
    if (Object.keys(data).length > 0) {
      data.forEach((item) => {
        newCSVData.push([
          item.uid,
          item.first_name,
          item.last_name,
          item.email,
          item.date_joined,
          item.num_active_orders.toString(),
          item.num_closed_orders.toString(),
          item.phone_number,
          item.birthday,
          item.companies,
          item.private_fridges,
          item.zip_code,
          item.favorite_items,
        ]);
      });
    } else {
      props.users.forEach((user) => {
        newCSVData.push([
          user._id ? user._id : '',
          user.first_name ? user.first_name : '',
          user.last_name ? user.last_name : '',
          user.email ? user.email : '',
          formatDate(new Date(user.date_account_created)),
          Object.keys(user.active_orders).length.toString(),
          Object.keys(user.closed_orders).length.toString(),
          user.phone_number ? user.phone_number : '',
          formatDate(new Date(user.birthday)),
          formatUserCompanies(user.private_companies).replace(',', ';'),
          formatUserPrivateFridges(user.private_fridges).replace(',', ';'),
          user.zip_code ? user.zip_code : '',
          formatUserProducts(user.favorite_item_ids),
        ]);
      });
    }

    let output = `${csvHeaders.join(',')}\n`;

    newCSVData.forEach((line) => {
      output += (`${line.join(',')}\n`);
    });

    exportToCSV(output);
  };

  const exportCSVVerbose = (event, data) => {
    if (Object.keys(data).length > 0) {
      // user selected specific columns
      exportToCSV(json2csv.parse(data.map((obj) => {
        return props.users.find((user) => {
          return user.id === obj.uid;
        });
      })));
    } else {
      // user selected all
      exportToCSV(json2csv.parse(props.users));
    }
  };

  const exportJSON = (event, data) => {
    if (Object.keys(data).length > 0) {
      // user selected specific columns
      exportToJson(data.map((obj) => {
        return props.users.find((user) => {
          return user.id === obj.uid;
        });
      }));
    } else {
      // user selected all
      exportToJson(props.users);
    }
  };

  return (
    <div>
      <MaterialTable
        title="6AM Health Users"
        icons={tableIcons}
        columns={columns}
        data={getUserData()}
        onRowClick={handleRowClick}
        options={{
          selection: true,
          filtering,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 75, 100],
        }}
        actions={[
          {
            tooltip: 'Export as CSV',
            icon: SaveAlt,
            onClick: exportCSV,
          },
          {
            tooltip: 'Export as CSV (verbose)',
            icon: AllInboxIcon,
            onClick: exportCSVVerbose,
          },
          {
            tooltip: 'Export as JSON',
            icon: SaveOutlinedIcon,
            onClick: exportJSON,
          },
          {
            tooltip: 'Export as CSV',
            icon: SaveAlt,
            isFreeAction: true,
            onClick: exportCSV,
          },
          {
            tooltip: 'Export as CSV (verbose)',
            icon: AllInboxIcon,
            isFreeAction: true,
            onClick: exportCSVVerbose,
          },
          {
            tooltip: 'Export as JSON',
            icon: SaveOutlinedIcon,
            isFreeAction: true,
            onClick: exportJSON,
          },
          {
            icon: FilterList,
            tooltip: 'Filter columns',
            isFreeAction: true,
            onClick: () => { setFiltering(!filtering); },
          },
        ]}
      />

      <ViewUser
        user={selectedUser}
        handleClose={handleModalClose}
        setUserPrivateCompanies={setUserCompanies}
        setUserPrivateFridges={setUserFridges}
        setUserActiveDiscount={setUserDiscount}
        setUserAdminUser={setUserAdminUser}
        setUserStripeTestMode={setUserStripeTestMode1}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users.users,
    companies: state.companies.companies,
    products: state.products.products,
    fridges: state.fridges.fridges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserPrivateCompanies: (uid, privateCompanies) => {
      dispatch(setUserPrivateCompanies(uid, privateCompanies));
    },
    setUserPrivateFridges: (uid, privateFridges) => {
      dispatch(setUserPrivateFridges(uid, privateFridges));
    },
    setUserActiveDiscount: (uid, activeDiscount) => {
      dispatch(setUserActiveDiscount(uid, activeDiscount));
    },
    setUserAdmin: (uid, bool) => {
      dispatch(setUserAdmin(uid, bool));
    },
    setUserStripeTestMode: (uid, bool) => {
      dispatch(setUserStripeTestMode(uid, bool));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
