import axios from 'axios';
import * as firebase from 'firebase';

const getAllTags = async () => {
  const response = await axios.get(`${global.API_URL}/tags`);
  return response.data;
};

const getTag = async (id) => {
  const response = await axios.get(`${global.API_URL}/tags/${id}`);
  return response.data;
};

const createTag = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.post(`${global.API_URL}/tags`, { authToken: idToken, fields });
  return response.data;
};

const updateTag = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/tags/${fields.id}`, { authToken: idToken, fields });
  return response.data;
};

const deleteTag = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.delete(`${global.API_URL}/tags/${id}`, { headers: { authorization: idToken } });
  return response.data;
};

export {
  getAllTags,
  getTag,
  createTag,
  updateTag,
  deleteTag,
};
