import React from 'react';
import * as firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';

import Orders from './tabs/orders/orders';
import POSSales from './tabs/pos-sales';
import POSDownload from './tabs/pos-downloads';
import Products from './tabs/products/products';
import Promotions from './tabs/promotions/promotions';
import Fridges from './tabs/fridges/fridges';
import Users from './tabs/users/users';
import POSDevices from './tabs/pos-devices';
import Companies from './tabs/companies/companies';
import Notifications from './tabs/notifications/notifications';
import Settings from './tabs/settings/settings';
import Inventory from './tabs/inventory';
import SideBar from './static/sidebar';

import { deauthAdminUser } from '../state/actions';
import { TAB_PATHS } from '../constants';

const drawerWidth = 210;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  fab: {
    backgroundColor: '#008037',
    color: 'white',
    fontFamily: 'avenir',
  },
  typography: {
    fontFamily: 'avenir',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#008037',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontFamily: 'avenir',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 800,
  },
  fixedHeightModal: {
    height: 125,
  },
  promotionBox: {
    height: 750,
    overflow: 'scroll',
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixedHeightPaperModal = clsx(classes.paper, classes.fixedHeightModal);
  const paperModal = clsx(classes.paper);

  const handleLogOut = () => {
    firebase.auth().signOut();
    props.deauthAdminUser();
    props.history.push('/');
  };

  const renderInnerTab = () => {
    switch (props.history.location.pathname) {
      case TAB_PATHS.ORDERS:
        return <Orders classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.POS_SALES:
        return <POSSales classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.POS_DOWNLOADS:
        return <POSDownload classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.PRODUCTS:
        return <Products classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.PROMOTIONS:
        return <Promotions classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.FRIDGES:
        return <Fridges classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.USERS:
        return <Users classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.COMPANIES:
        return <Companies classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.POS_DEVICES:
        return <POSDevices classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.NOTIFICATIONS:
        return <Notifications classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.SETTINGS:
        return <Settings classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      case TAB_PATHS.INVENTORY:
        return <Inventory classes={classes} fixedHeightPaper={fixedHeightPaper} fixedHeightPaperModal={fixedHeightPaperModal} paperModal={paperModal} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => { setOpen(true); }}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            <b>6AM Health Admin Portal</b>
            <div className="welcome" style={{ float: 'right', fontSize: 16 }}>
              Welcome, <b>{firebase.auth().currentUser.displayName || firebase.auth().currentUser.email || 'Admin User'}</b>
              <Button variant="outlined"
                style={{
                  fontFamily: 'avenir', color: 'white', marginLeft: 20, borderWidth: 1, borderColor: 'white', fontSize: 10, fontWeight: 'bold',
                }}
                color="white"
                onClick={handleLogOut}
              >
                Log out
              </Button>
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => { setOpen(false); }}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <SideBar />
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {renderInnerTab()}
        </Container>
      </main>
    </div>
  );
};

export default withRouter(connect(null, { deauthAdminUser })(Dashboard));
