import { ActionTypes } from '../actions';

// initial state
const initialState = {
  users: [],
};

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_USERS:
      return { ...state, users: action.payload };
    default:
      return state;
  }
};

export default UsersReducer;
