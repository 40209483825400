import axios from 'axios';
import * as firebase from 'firebase';

const getAllOrders = async () => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.get(`${global.API_URL}/orders`, { headers: { authorization: idToken } });
  return response.data;
};

const getAllPublicOrders = async () => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.get(`${global.API_URL}/orders/public`, { headers: { authorization: idToken } });
  return response.data;
};

const createPublicOrder = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.post(`${global.API_URL}/orders/public`, { authToken: idToken, fields });
  return response.data;
};

const isValidPublicOrder = async (fields) => {
  const response = await axios.post(`${global.API_URL}/orders/public/valid-order`, { fields });
  return response.data;
};

const getAllPrivateOrders = async () => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.get(`${global.API_URL}/orders/private`, { headers: { authorization: idToken } });
  return response.data;
};

const createPrivateOrder = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.post(`${global.API_URL}/orders/private`, { authToken: idToken, fields });
  return response.data;
};

const isValidPrivateOrder = async (fields) => {
  const response = await axios.post(`${global.API_URL}/orders/private/valid-order`, { fields });
  return response.data;
};

const getEstimatedDeliveryDate = async () => {
  const response = await axios.get(`${global.API_URL}/orders/private/delivery-date`);
  return response.data;
};

const getOrder = async (id) => {
  const response = await axios.get(`${global.API_URL}/orders/${id}`);
  return response.data;
};

const updateOrder = async (id, fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/orders/${id}`, { authToken: idToken, fields });
  return response.data;
};

const deleteOrder = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.delete(`${global.API_URL}/orders/${id}`, { headers: { authorization: idToken } });
  return response.data;
};

const updatedScheduledDeliveryDate = async (id, date) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/orders/scheduled-delivery-date/${id}`, { authToken: idToken, scheduled_delivery_date: date });
  return response.data;
};

const closeOrder = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/orders/close/${id}`, { authToken: idToken });
  return response.data;
};

const openOrder = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/orders/open/${id}`, { authToken: idToken });
  return response.data;
};

export {
  getAllOrders,
  getAllPublicOrders,
  createPublicOrder,
  isValidPublicOrder,
  getAllPrivateOrders,
  createPrivateOrder,
  isValidPrivateOrder,
  getEstimatedDeliveryDate,
  getOrder,
  updateOrder,
  deleteOrder,
  closeOrder,
  openOrder,
  updatedScheduledDeliveryDate,
};
