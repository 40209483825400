/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';

import DevicePreview from './device-preview';
import DeviceView from './device-view';
import { createPOSDevice, updatePOSDeviceById, deletePOSDeviceById } from '../../../state/actions';

import '../../../styles/companies.scss';

class Devices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      device: null,
      add: false,
    };
  }

  // Immutably update "device" field
  handleDeviceClick = (id, name, fridge_id, passcode) => {
    this.setState(prevState => ({
      device: {
        ...prevState.device, id, name, fridge_id, passcode,
      },
    }));
  };

  renderPOSDevices = () => {
    // this.props.devices.sort((a, b) => ((a.name > b.name) ? 1 : -1));

    return this.props.devices.map((device) => {
      return (
        <DevicePreview
          id={device ? device.id : undefined}
          name={device ? device.name : undefined}
          fridge_id={device ? device.fridge_id : undefined}
          passcode={device ? device.passcode : undefined}
          handleClick={this.handleDeviceClick}
        />
      );
    });
  };

  createDevice = (fields) => {
    const { id, ...sendFields } = fields;
    this.props.createPOSDevice(id, sendFields);
  }

  updateDevice = (fields) => {
    const { id, ...sendFields } = fields;
    this.props.updatePOSDeviceById(id, sendFields);
  }

  deleteDevice = (id) => {
    this.props.deletePOSDeviceById(id);
  }

  handleModalClose = () => {
    this.setState({
      device: null,
      add: false,
    });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className="paper-container">
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>POS Devices</b>
              <div id="private-company-container">
                {this.renderPOSDevices()}
              </div>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={this.props.fixedHeightPaperModal}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>Add New Device</b>
            </Typography>
            <Fab variant="extended"
              aria-label="like"
              style={{
                color: 'white', fontFamily: 'avenir', fontWeight: 'bold', backgroundColor: '#008037',
              }}
              onClick={() => { this.setState({ add: true }); }}
            >
              Add New Device
            </Fab>
          </Paper>
        </Grid>

        <DeviceView
          id={this.state.device ? this.state.device.id : undefined}
          name={this.state.device ? this.state.device.name : ''}
          fridge_id={this.state.device ? this.state.device.fridge_id : ''}
          fridges={this.props.fridges}
          passcode={this.state.device ? this.state.device.passcode : ''}
          add={this.state.add}
          handleFieldUpdate={(k, v) => this.setState(prevState => ({ device: { ...prevState.device, [k]: v } }))}
          onCreate={this.createDevice}
          onUpdate={this.updateDevice}
          onDelete={this.deleteDevice}
          onClose={this.handleModalClose}
        />

        {/* <DeviceView
          handleClose={this.handleModalClose}
          createDevice={this.createDevice}
          add={this.state.add}
        /> */}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.posDevices.devices || [],
  };
};

export default connect(mapStateToProps, {
  createPOSDevice,
  updatePOSDeviceById,
  deletePOSDeviceById,
})(Devices);
