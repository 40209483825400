export const getFridgeName = (fridges, fridgeId) => {
  const fridge = fridges.find(obj => obj._id === fridgeId);

  if (fridge) return fridge.name || '';
  return '';
};

export const formatDate = (date) => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${month}/${day}/${year}`;
};

export const getUserName = (users, order) => {
  const {
    guest_name: guestName,
    user_id: userId,
  } = order;

  if (guestName) return guestName;

  const user = users.find(obj => obj._id === userId);

  if (user) return `${user.first_name || ''} ${user.last_name || ''}` || '';
  return '';
};

export const dateInLastMonth = (date) => {
  // https://stackoverflow.com/questions/6154689/how-to-check-if-date-is-within-30-days
  const numDaysBetween = (d1, d2) => {
    const diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
  };

  return numDaysBetween(new Date(), date) <= 30;
};
