/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Edit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { connect } from 'react-redux';

class ViewFridge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      name: '',
      location_general: '',
      location_specific: '',
      latitude: '',
      longitude: '',
      tax_rate: '',
      is_public: false,
      specific_instructions: '',
      private_companies: [],
      image_id: '',
      access_pin: Math.floor(1000 + Math.random() * 9000),
      active_discount: {},
    };
  }

  submit = () => {
    if (this.props.add) {
      this.props.createFridge(this.state);
    } else {
      this.props.updateFridge(this.state);
    }

    this.close();
  }

  delete = () => {
    this.props.deleteFridge(this.state.id);
    this.close();
  }

  close = () => {
    this.setState({
      id: '',
      name: '',
      location_general: '',
      location_specific: '',
      latitude: '',
      longitude: '',
      tax_rate: '',
      is_public: '',
      specific_instructions: '',
      private_companies: [],
      image_id: '',
      access_pin: Math.floor(1000 + Math.random() * 9000),
      active_discount: {},
    });

    this.props.handleClose();
  }

  renderPrivateCompanies = () => {
    return (
      <div>
        <DialogContentText style={{ marginTop: '15px' }}>
          Private companies with access:
        </DialogContentText>
        {this.state.private_companies.map((company) => {
          return (
            <FormControlLabel
              control={(
                <Checkbox
                  checked
                  onChange={(e) => {
                    const newCompanies = [];
                    this.state.private_companies.forEach((privCompany) => {
                      if (privCompany !== company) {
                        newCompanies.push(privCompany);
                      }
                    });

                    this.setState({
                      private_companies: newCompanies,
                    });
                  }}
                  value="private_company"
                />
                  )}
              label={this.props.companies.find(aCompany => aCompany.id === company).name}
            />
          );
        })}
      </div>
    );
  }

  addPrivateCompany = (id) => {
    const newCompanies = [];
    this.state.private_companies.forEach((privCompany) => {
      newCompanies.push(privCompany);
    });

    newCompanies.push(id);

    this.setState({
      private_companies: newCompanies,
    });
  }

  renderAddPrivateCompany = () => {
    const numMenuItems = this.props.companies.filter((company) => {
      return !this.state.private_companies.includes(company.id);
    }).length;

    if (numMenuItems === 0) {
      return null;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                Add Private Company
              </Button>
              <Menu {...bindMenu(popupState)}>
                {this.props.companies.map((company) => {
                  if (!this.state.private_companies.includes(company.id)) {
                    return (
                      <MenuItem onClick={(e) => {
                        popupState.close(e);
                        this.addPrivateCompany(company.id);
                      }}
                      >{company.name}
                      </MenuItem>
                    );
                  }

                  return null;
                })}
              </Menu>
            </>
          )}
        </PopupState>
      </div>
    );
  }

  renderActiveDiscount = () => {
    if (this.state.is_public) {
      return null;
    }

    if (Object.keys(this.state.active_discount).length === 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '5px', marginRight: '5px' }}
            onClick={() => {
              this.setState({
                active_discount: {
                  amount: 0,
                  percent: true,
                  title: 'New Discount',
                  message: 'Enjoy a new discount on us!',
                  imageID: '1OPvEUOuwKSeGAM1RxxF8Lp9wB0GjrxcJ',
                },
              });
            }}
          >
            Add Fridge Discount
          </Button>
        </div>
      );
    }

    return (
      <div style={{ marginTop: '20px' }}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={this.state.active_discount.percent}
              onChange={() => {
                const newObj = { ...this.state.active_discount };
                newObj.percent = !newObj.percent;
                this.setState({ active_discount: newObj });
              }}
              value="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
          label="Percentage off"
        />

        <FormControlLabel
          control={(
            <Checkbox
              checked={!this.state.active_discount.percent}
              onChange={() => {
                const newObj = { ...this.state.active_discount };
                newObj.percent = !newObj.percent;
                this.setState({ active_discount: newObj });
              }}
              value="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
          label="Dollar amount off"
        />

        <TextField
          required
          margin="dense"
          label="Amount (if percent between 0 and 1)"
          defaultValue={this.state.active_discount.amount}
          onChange={(e) => {
            const newObj = { ...this.state.active_discount };
            newObj.amount = e.target.value;
            this.setState({ active_discount: newObj });
          }}
          fullWidth
        />

        <TextField
          required
          margin="dense"
          label="Title"
          defaultValue={this.state.active_discount.title}
          onChange={(e) => {
            const newObj = { ...this.state.active_discount };
            newObj.title = e.target.value;
            this.setState({ active_discount: newObj });
          }}
          fullWidth
        />

        <TextField
          required
          margin="dense"
          label="Message"
          defaultValue={this.state.active_discount.message}
          onChange={(e) => {
            const newObj = { ...this.state.active_discount };
            newObj.message = e.target.value;
            this.setState({ active_discount: newObj });
          }}
          fullWidth
        />

        <TextField
          required
          margin="dense"
          label="Image ID (leave empty if don't want to appear)"
          defaultValue={this.state.active_discount.imageID}
          onChange={(e) => {
            const newObj = { ...this.state.active_discount };
            newObj.imageID = e.target.value;
            this.setState({ active_discount: newObj });
          }}
          fullWidth
        />

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '5px', marginRight: '5px' }}
            onClick={() => {
              this.setState({
                active_discount: {},
              });
            }}
          >
            Remove Discount
          </Button>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if (props.fridge) {
      this.setState({
        id: props.fridge._id,
        name: props.fridge.name,
        location_general: props.fridge.location_general,
        location_specific: props.fridge.location_specific,
        latitude: props.fridge.latitude,
        longitude: props.fridge.longitude,
        tax_rate: props.fridge.tax_rate,
        is_public: props.fridge.is_public,
        specific_instructions: props.fridge.specific_instructions,
        private_companies: props.fridge.private_companies,
        image_id: props.fridge.image_id,
        access_pin: props.fridge.access_pin,
        active_discount: props.fridge.active_discount || {},
      });
    }
  }

  render() {
    if (this.props.fridge || this.props.add) {
      return (
        <div>
          <Dialog open onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{this.props.fridge ? this.props.fridge.name : 'Create New Fridge'}</DialogTitle>
            <DialogContent style={{ marginTop: '-15px', width: '450px' }}>
              <DialogContentText>
                Edit details about this fridge below
              </DialogContentText>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={!this.state.is_public}
                    onChange={e => this.setState({ is_public: !this.state.is_public })}
                    value="is_public"
                  />
                )}
                label="Can order ahead"
                style={{ marginBottom: '10px' }}
              />
              <TextField
                required
                margin="dense"
                label="Fridge name"
                defaultValue={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Location general"
                defaultValue={this.state.location_general}
                onChange={e => this.setState({ location_general: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Location specific"
                defaultValue={this.state.location_specific}
                onChange={e => this.setState({ location_specific: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Latitude"
                defaultValue={this.state.latitude}
                onChange={e => this.setState({ latitude: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Longitude"
                defaultValue={this.state.longitude}
                onChange={e => this.setState({ longitude: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Tax rate"
                defaultValue={this.state.tax_rate}
                onChange={e => this.setState({ tax_rate: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Specific instructions"
                defaultValue={this.state.specific_instructions}
                onChange={e => this.setState({ specific_instructions: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="PIN (for manually allowing pre-orders)"
                defaultValue={this.state.access_pin}
                onChange={e => this.setState({ access_pin: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Logo image ID"
                defaultValue={this.state.image_id}
                onChange={e => this.setState({ image_id: e.target.value })}
                fullWidth
              />

              {this.renderPrivateCompanies()}
              {this.renderAddPrivateCompany()}
              {this.renderActiveDiscount()}

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Button variant="outlined"
                  color="primary"
                  onClick={this.submit}
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                >
                  Submit
                </Button>
                {this.props.deleteFridge
                  ? (
                    <Button variant="outlined"
                      color="primary"
                      onClick={this.delete}
                      style={{ marginLeft: '5px', marginRight: '5px' }}
                    >
                      Delete
                    </Button>
                  ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.close} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companies.companies,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ViewFridge);
