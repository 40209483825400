import React from 'react';
import ReactToPrint from 'react-to-print';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import Edit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';
import { closeOrder, openOrder, updatedScheduledDeliveryDate } from '../../../state/actions';
import '../../../styles/orders.scss';

class ViewOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryDate: null,
    };

    this.printRef = React.createRef();
  }

  renderProductImages = () => {
    return Object.keys(this.props.order.items).map((productId) => {
      const item = this.props.order.items[productId];
      const productName = item.productInfo.name;
      const image = (item.productInfo.image_id ? (
        <img className="product-image"
          src={`https://drive.google.com/uc?export=view&id=${item.productInfo.image_id}`}
          alt={productName}
        />
      ) : <div className="no-image" />);

      return (
        <div className="product-area">
          {image}
          <div className="product-text-area">
            <p>{item.productInfo.name}</p>
            <p>{item.count}</p>
          </div>
        </div>
      );
    });
  };

  renderProductPrintImages = () => {
    const output = [];

    let totalCount = 0;

    Object.keys(this.props.order.items).forEach((productId) => {
      totalCount += this.props.order.items[productId].count;
    });

    Object.keys(this.props.order.items).forEach((productId) => {
      const item = this.props.order.items[productId];
      const productName = item.productInfo.name;
      const image = (item.productInfo.name ? (
        <img className="product-image"
          src={`https://drive.google.com/uc?export=view&id=${item.productInfo.image_id}`}
          alt={productName}
        />
      ) : <div className="no-image" />);

      for (let i = 1; i <= item.count; i += 1) {
        output.push((
          <div className="print-label">
            <div>
              <h2 className="print-label-user">{this.props.order.user_name}</h2>
            </div>
            <div>
              <h2 className="print-label-fridge">{this.props.order.fridge_name}</h2>
            </div>
            <div id="product-image-area">
              <div className="product-area">
                {image}
                <div className="product-text-area">
                  <p>{item.productInfo.name}</p>
                  <p>{`${i} of ${item.count}`}</p>
                  <p className="product-text-no-top">{`Items in order: ${totalCount}`}</p>
                </div>
              </div>
            </div>
            <p className="product-text-delivery-date">{`Delivered on: ${this.props.order.delivery_date_string}`}</p>
          </div>
        ));
      }
    });

    return output;
  };

  closeThisOrder = () => {
    this.props.closeOrder(this.props.order.id);
    this.props.handleClose();
  };

  openThisOrder = () => {
    this.props.openOrder(this.props.order.id);
    this.props.handleClose();
  };

  // change state stored value
  changeDeliveryDate = (dateObject, dateString) => {
    this.setState({
      deliveryDate: dateString,
    });
  };

  // update actual order object
  updateDeliveryDate = () => {
    this.props.updatedScheduledDeliveryDate(this.props.order.id, new Date(this.state.deliveryDate));
    this.props.handleClose();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.order) {
      this.setState({
        deliveryDate: newProps.order.delivery_date_string,
      });
    }
  }

  render() {
    if (this.props.order === null) {
      return null;
    }

    return (
      <div>
        <Dialog open onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{`Order for ${this.props.order.user_name} at ${this.props.order.fridge_name}`}</DialogTitle>
          <DialogContent style={{ marginTop: '-15px' }}>
            <div id="order-info-area">
              <p>{`Total: $${this.props.order.total ? this.props.order.total.toFixed(2) : 0}`}</p>
              <p>{`Delivering on: ${this.props.order.delivery_date_string}`}</p>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Change delivery date"
                  value={this.state.deliveryDate}
                  onChange={this.changeDeliveryDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              {this.state.deliveryDate !== this.props.order.delivery_date_string ? (
                <Button
                  className="submit-button"
                  id="save-delivery-date-button"
                  variant="outlined"
                  color="primary"
                  onClick={this.updateDeliveryDate}
                >
                  Save and Notify User
                </Button>
              ) : null}

            </div>
            <div id="product-image-area">
              <h4>Items:</h4>
              {this.renderProductImages()}
            </div>
            {(this.props.order.special_instructions && this.props.order.special_instructions.length > 0) ? (
              <div id="product-image-area">
                <h4>Special Instructions:</h4>
                <p style={{ marginTop: '-17px' }}>{this.props.order.special_instructions}</p>
              </div>
            ) : null}
            <div id="bottom-button-area">
              <ReactToPrint
                trigger={() => (
                  <Button
                    className="submit-button"
                    variant="outlined"
                    color="primary"
                  >
                    Print Label
                  </Button>
                )}
                content={() => this.printRef.current}
              />

              {this.props.order.is_open ? (
                <Button
                  className="submit-button"
                  variant="outlined"
                  color="primary"
                  onClick={this.closeThisOrder}
                >
                  Close Order
                </Button>
              ) : (
                <Button
                  className="submit-button"
                  variant="outlined"
                  color="primary"
                  onClick={this.openThisOrder}
                >
                  Open Order
                </Button>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* render the label to be printed */}
        <div id="print-label-container">
          <div id="print-label-items" ref={this.printRef}>
            {this.renderProductPrintImages()}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeOrder: (id) => {
      dispatch(closeOrder(id));
    },
    openOrder: (id) => {
      dispatch(openOrder(id));
    },
    updatedScheduledDeliveryDate: (id, date) => {
      dispatch(updatedScheduledDeliveryDate(id, date));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(ViewOrder);
