import axios from 'axios';
import * as firebase from 'firebase';

const getAllPromotions = async () => {
  const response = await axios.get(`${global.API_URL}/promotions`);
  return response.data;
};

const getCurrentPOSPromotions = async () => {
  const response = await axios.get(`${global.API_URL}/promotions/current/pos`);
  return response.data;
};

const getCurrentMobilePromotions = async () => {
  const response = await axios.get(`${global.API_URL}/promotions/current/mobile`);
  return response.data;
};

const getPromotion = async (id) => {
  const response = await axios.get(`${global.API_URL}/promotions/${id}`);
  return response.data;
};

const createPromotion = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.post(`${global.API_URL}/promotions`, { authToken: idToken, fields });
  return response.data;
};

const updatePromotion = async (fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.put(`${global.API_URL}/promotions/${fields.id}`, { authToken: idToken, fields });
  return response.data;
};

const deletePromotion = async (id) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.delete(`${global.API_URL}/promotions/${id}`, { headers: { authorization: idToken } });
  return response.data;
};

export {
  getAllPromotions,
  getCurrentPOSPromotions,
  getCurrentMobilePromotions,
  getPromotion,
  createPromotion,
  updatePromotion,
  deletePromotion,
};
