/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import '../../../styles/companies.scss';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import ProductPreview from './product-preview';
import ViewProduct from './view-product';
import {
  createProduct, updateProduct, deleteProduct,
} from '../../../state/actions';

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      add: false,
    };
  }

  handleProductClick = (product) => {
    this.setState({
      product,
    });
  };

  renderProducts = (visible) => {
    return this.props.products.filter(product => product.visible === visible).map((product) => {
      return <ProductPreview product={product} handleClick={this.handleProductClick} />;
    });
  };

  createProductLocal = (fields) => {
    this.props.createProduct(fields);
  }

  updateProductLocal = (fields) => {
    const fieldsToSet = { ...fields };
    delete fieldsToSet.id;
    delete fieldsToSet.imageId;
    delete fieldsToSet.addNutrientField;

    this.props.updateProduct(fields.id, fieldsToSet);
  }

  deleteProductLocal = (id) => {
    this.props.deleteProduct(id);
  }

  handleModalClose = () => {
    this.setState({
      product: null,
      add: false,
    });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className="paper-container">
            <Typography component="h1" variant="h6" color="inherit" className={this.props.classes.title}>
              <b>Visible Products</b>
              <div id="product-container">
                {this.renderProducts(true)}
              </div>
            </Typography>
          </Paper>
          <Paper className="paper-container">
            <Typography component="h1" variant="h6" color="inherit" className={this.props.classes.title}>
              <b>Unlisted Products (not visible in app)</b>
              <div id="product-container">
                {this.renderProducts(false)}
              </div>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={this.props.fixedHeightPaperModal}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={this.props.classes.title}>
              <b>Add New Product</b>
            </Typography>
            <Fab variant="extended"
              aria-label="like"
              style={{
                color: 'white', fontFamily: 'avenir', fontWeight: 'bold', backgroundColor: '#008037',
              }}
              onClick={() => { this.setState({ add: true }); }}
            >
              Add New Product
            </Fab>
          </Paper>
        </Grid>

        <ViewProduct
          product={this.state.product}
          handleClose={this.handleModalClose}
          updateProduct={this.updateProductLocal}
          deleteProduct={this.deleteProductLocal}
        />

        <ViewProduct
          handleClose={this.handleModalClose}
          createProduct={this.createProductLocal}
          add={this.state.add}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (fields) => {
      dispatch(createProduct(fields));
    },
    updateProduct: (id, fields) => {
      dispatch(updateProduct(id, fields));
    },
    deleteProduct: (id) => {
      dispatch(deleteProduct(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Products);
