import * as userRequests from '../../services/user-requests';

// keys for actiontypes
const ActionTypes = {
  GET_ADMIN_AUTHID: 'GET_ADMIN_AUTHID',
  DEAUTH: 'DEAUTH',
};

const authAdminUser = (token) => {
  return async (dispatch) => {
    if (token) {
      try {
        await userRequests.isAdminUser(token);
        dispatch({ type: ActionTypes.GET_ADMIN_AUTHID, payload: token });
      } catch (error) {
        dispatch({ type: ActionTypes.DEAUTH });
        console.error('Admin authentication failed');
        throw error;
      }
    }
  };
};

const deauthAdminUser = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DEAUTH });
  };
};

export { ActionTypes, authAdminUser, deauthAdminUser };
