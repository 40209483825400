// import all actions and action types from various action files
import {
  ActionTypes as authActionTypes,
  authAdminUser,
  deauthAdminUser,
} from './auth-actions';

import {
  ActionTypes as appActionTypes,
  fetchRemoteAppConfig,
} from './app-actions';

import {
  ActionTypes as fridgeActionTypes,
  getAllFridges,
  createFridge,
  updateFridge,
  updateFridgeInventory,
  deleteFridge,
} from './fridge-actions';

import {
  ActionTypes as promotionActionTypes,
  getAllPromotions,
  getCurrentPOSPromotions,
  getCurrentMobilePromotions,
  createPromotion,
  updatePromotion,
  deletePromotion,
} from './promotion-actions';

import {
  ActionTypes as userActionTypes,
  getAllUsers,
  setUserPrivateCompanies,
  setUserPrivateFridges,
  setUserActiveDiscount,
  setUserAdmin,
  setUserStripeTestMode,
} from './user-actions';

import {
  ActionTypes as companyActionTypes,
  getAllPrivateCompanies,
  getPrivateCompany,
  createPrivateCompany,
  updatePrivateCompany,
  deletePrivateCompany,
  getFridgesForPrivateCompany,
} from './company-actions';

import {
  ActionTypes as orderActionTypes,
  getAllOrders,
  closeOrder,
  openOrder,
  updateOrder,
  updatedScheduledDeliveryDate,
} from './order-actions';

import {
  ActionTypes as posDevicesActionTypes,
  fetchAllPOSDevices,
  fetchPOSDeviceById,
  createPOSDevice,
  updatePOSDeviceById,
  deletePOSDeviceById,
} from './pos-device-actions';

import {
  ActionTypes as productActionTypes,
  getAllProducts,
  createProduct,
  updateProduct,
  deleteProduct,
} from './product-actions';

import {
  ActionTypes as settingActionTypes,
  getAllSettings,
  getSetting,
  createSetting,
  updateSetting,
  deleteSetting,
} from './settings-actions';

import {
  ActionTypes as tagActionTypes,
  get6AMTags,
  create6AMTag,
  update6AMTag,
  delete6AMTag,
} from './tag-actions';

// combine all action types
const ActionTypes = {};

Object.keys(authActionTypes).forEach((key) => {
  ActionTypes[key] = authActionTypes[key];
});

Object.keys(appActionTypes).forEach((key) => {
  ActionTypes[key] = appActionTypes[key];
});

Object.keys(fridgeActionTypes).forEach((key) => {
  ActionTypes[key] = fridgeActionTypes[key];
});

Object.keys(promotionActionTypes).forEach((key) => {
  ActionTypes[key] = promotionActionTypes[key];
});

Object.keys(userActionTypes).forEach((key) => {
  ActionTypes[key] = userActionTypes[key];
});

Object.keys(orderActionTypes).forEach((key) => {
  ActionTypes[key] = orderActionTypes[key];
});

Object.keys(posDevicesActionTypes).forEach((key) => {
  ActionTypes[key] = posDevicesActionTypes[key];
});

Object.keys(productActionTypes).forEach((key) => {
  ActionTypes[key] = productActionTypes[key];
});

Object.keys(companyActionTypes).forEach((key) => {
  ActionTypes[key] = companyActionTypes[key];
});

Object.keys(settingActionTypes).forEach((key) => {
  ActionTypes[key] = settingActionTypes[key];
});

Object.keys(tagActionTypes).forEach((key) => {
  ActionTypes[key] = tagActionTypes[key];
});

// export all action types in one object, as well as each action
export {
  ActionTypes,
  authAdminUser,
  deauthAdminUser,

  getAllFridges,
  createFridge,
  updateFridge,
  updateFridgeInventory,
  deleteFridge,

  getAllPromotions,
  getCurrentPOSPromotions,
  getCurrentMobilePromotions,
  createPromotion,
  updatePromotion,
  deletePromotion,

  get6AMTags,
  create6AMTag,
  update6AMTag,
  delete6AMTag,

  getAllUsers,
  setUserPrivateCompanies,
  setUserPrivateFridges,
  setUserActiveDiscount,
  setUserAdmin,
  setUserStripeTestMode,

  getAllOrders,
  updatedScheduledDeliveryDate,
  updateOrder,
  closeOrder,
  openOrder,

  fetchAllPOSDevices,
  fetchPOSDeviceById,
  createPOSDevice,
  updatePOSDeviceById,
  deletePOSDeviceById,

  getAllProducts,
  createProduct,
  updateProduct,
  deleteProduct,

  getAllPrivateCompanies,
  getPrivateCompany,
  createPrivateCompany,
  updatePrivateCompany,
  deletePrivateCompany,
  getFridgesForPrivateCompany,

  getAllSettings,
  getSetting,
  createSetting,
  updateSetting,
  deleteSetting,

  fetchRemoteAppConfig,
};
