/* eslint-disable no-param-reassign */
import * as promotionRequests from '../../services/promotion-requests';

// keys for actiontypes
const ActionTypes = {
  GET_ALL_PROMOTIONS: 'GET_ALL_PROMOTIONS',
  GET_CURRENT_POS_PROMOTIONS: 'GET_CURRENT_POS_PROMOTIONS',
  GET_CURRENT_MOBILE_PROMOTIONS: 'GET_CURRENT_MOBILE_PROMOTIONS',
};

const getAllPromotions = () => {
  return async (dispatch) => {
    const data = await promotionRequests.getAllPromotions();
    dispatch({ type: ActionTypes.GET_ALL_PROMOTIONS, payload: data });
  };
};

const getCurrentPOSPromotions = () => {
  return async (dispatch) => {
    const data = await promotionRequests.getCurrentPOSPromotions();
    dispatch({ type: ActionTypes.GET_CURRENT_POS_PROMOTIONS, payload: data });
  };
};

const getCurrentMobilePromotions = () => {
  return async (dispatch) => {
    const data = await promotionRequests.getCurrentMobilePromotions();
    dispatch({ type: ActionTypes.GET_CURRENT_MOBILE_PROMOTIONS, payload: data });
  };
};

const createPromotion = (fields) => {
  return async (dispatch) => {
    fields.display_template = 'basic';
    await promotionRequests.createPromotion(fields);

    const promotionData = await promotionRequests.getAllPromotions();
    dispatch({ type: ActionTypes.GET_ALL_PROMOTIONS, payload: promotionData });

    const currentData = await promotionRequests.getCurrentPOSPromotions();
    dispatch({ type: ActionTypes.GET_CURRENT_POS_PROMOTIONS, payload: currentData });

    const mobileData = await promotionRequests.getCurrentMobilePromotions();
    dispatch({ type: ActionTypes.GET_CURRENT_MOBILE_PROMOTIONS, payload: mobileData });
  };
};

const updatePromotion = (fields) => {
  return async (dispatch) => {
    await promotionRequests.updatePromotion(fields);

    const promotionData = await promotionRequests.getAllPromotions();
    dispatch({ type: ActionTypes.GET_ALL_PROMOTIONS, payload: promotionData });

    const currentData = await promotionRequests.getCurrentPOSPromotions();
    dispatch({ type: ActionTypes.GET_CURRENT_POS_PROMOTIONS, payload: currentData });

    const mobileData = await promotionRequests.getCurrentMobilePromotions();
    dispatch({ type: ActionTypes.GET_CURRENT_MOBILE_PROMOTIONS, payload: mobileData });
  };
};

const deletePromotion = (id) => {
  return async (dispatch) => {
    await promotionRequests.deletePromotion(id);

    const promotionData = await promotionRequests.getAllPromotions();
    dispatch({ type: ActionTypes.GET_ALL_PROMOTIONS, payload: promotionData });

    const currentData = await promotionRequests.getCurrentPOSPromotions();
    dispatch({ type: ActionTypes.GET_CURRENT_POS_PROMOTIONS, payload: currentData });

    const mobileData = await promotionRequests.getCurrentMobilePromotions();
    dispatch({ type: ActionTypes.GET_CURRENT_MOBILE_PROMOTIONS, payload: mobileData });
  };
};

export {
  ActionTypes,
  getAllPromotions,
  getCurrentPOSPromotions,
  getCurrentMobilePromotions,
  createPromotion,
  updatePromotion,
  deletePromotion,
};
