/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as firebase from 'firebase';

const isAdminUser = async (token) => {
  const response = await axios.post(`${global.API_URL}/users/admin/verify`, { token });
  return response.data;
};

const getAllUsers = async () => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.get(`${global.API_URL}/users`, { headers: { authorization: idToken } });
  return response.data;
};

const updateUser = async (uid, fields) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.post(`${global.API_URL}/users/admin/update/${idToken}`, { uid, fields });
  return response.data;
};

const getOAuthToken = async (idToken) => {
  const response = await axios.get(`${global.API_URL}/users/oauth/token/${idToken}`, { headers: { 'x-sixamhealth-referer': '8WhFwkKB69M7EhE' } });
  return response.data;
};

const setUserPrivateCompanies = (uid, privateCompanies) => {
  return updateUser(uid, {
    private_companies: privateCompanies,
  });
};

const setUserPrivateFridges = (uid, privateFridges) => {
  return updateUser(uid, {
    private_fridges: privateFridges,
  });
};

const setUserActiveDiscount = (uid, activeDiscount) => {
  return updateUser(uid, {
    active_discount: activeDiscount,
  });
};

const setUserAdmin = (uid, bool) => {
  return updateUser(uid, {
    admin_user: bool,
  });
};

const setUserStripeTestMode = (uid, bool) => {
  return updateUser(uid, {
    stripe_test_mode: bool,
  });
};

export {
  getAllUsers,
  getOAuthToken,
  isAdminUser,
  setUserActiveDiscount,
  setUserAdmin,
  setUserPrivateCompanies,
  setUserPrivateFridges,
  setUserStripeTestMode,
  updateUser,
};
