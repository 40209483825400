/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as firebase from 'firebase';
import '../styles/home.scss';
import sixamLogo from '../assets/6amhealth_logo_white.png';
import {
  getAllFridges, authAdminUser, getAllPromotions, getAllProducts, getAllSettings,
  getAllUsers, get6AMTags, getAllOrders, getCurrentPOSPromotions, getCurrentMobilePromotions, getAllPrivateCompanies,
} from '../state/actions/index';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  handleLogin = () => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
          .then(() => {
            firebase.auth().currentUser.getIdToken(true)
              .then((idToken) => {
                this.props.authAdminUser(idToken).then(() => {
                  // load data and re-route
                  this.loadData();
                  this.props.history.push('/orders');
                });
              });
          }).catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleLoginGoogle = () => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
          firebase.auth().currentUser.getIdToken(true)
            .then((idToken) => {
              this.props.authAdminUser(idToken).then(() => {
                // load data and re-route
                this.loadData();
                this.props.history.push('/orders');
              });
            });
        }).catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleLoginFacebook = () => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
          firebase.auth().currentUser.getIdToken(true)
            .then((idToken) => {
              this.props.authAdminUser(idToken).then(() => {
                // load data and re-route
                this.loadData();
                this.props.history.push('/orders');
              });
            });
        }).catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadData = () => {
    this.props.getAllFridges();
    this.props.getAllPromotions();
    this.props.getCurrentPOSPromotions();
    this.props.getCurrentMobilePromotions();
    this.props.getAllUsers();
    this.props.get6AMTags();
    this.props.getAllOrders();
    this.props.getAllProducts();
    this.props.getAllPrivateCompanies();
    this.props.getAllProducts();
    this.props.getAllSettings();
  }

  render() {
    return (
      <div>
        <div id="home">
          <div id="flex-area">
            <img src={sixamLogo} alt="6amhealth logo" />
            <Paper id="login" elevation={16}>
              <Typography variant="h4" color="primary">Admin Portal</Typography>
              <div id="loginInputArea">
                <TextField className="loginInput" variant="outlined" margin="dense" type="email" placeholder="Administrator email" fullWidth value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                <TextField className="loginInput" variant="outlined" margin="dense" type="password" placeholder="Password" fullWidth value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
              </div>
              <Button color="primary" variant="contained" onClick={this.handleLogin}>Login</Button>

              <div id="auth-button-container">
                <div id="auth-line" />
                <Button id="auth-google" color="primary" variant="outlined" onClick={this.handleLoginGoogle}>Login With Google</Button>
                <Button id="auth-fb" color="primary" variant="outlined" onClick={this.handleLoginFacebook}>Login With Facebook</Button>
              </div>
            </Paper>
          </div>
          <div id="footer-area">
            <NavLink to="/terms" exact>Terms &amp; Conditions</NavLink>
            <NavLink to="/privacy" exact>Privacy Policy</NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, {
  getAllFridges,
  authAdminUser,
  getAllPromotions,
  getCurrentPOSPromotions,
  getCurrentMobilePromotions,
  getAllUsers,
  get6AMTags,
  getAllOrders,
  getAllProducts,
  getAllPrivateCompanies,
  getAllSettings,
})(Home));
