import { ActionTypes } from '../actions';

// initial state
const initialState = {
  devices: [],
};

const posDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_POS_DEVICE:
      return { ...state, devices: state.devices.slice().map(e => (e._id === action.payload._id ? action.payload : e)) };
    case ActionTypes.CREATE_POS_DEVICE:
      return { ...state, devices: [...state.devices, action.payload] };
    case ActionTypes.UPDATE_POS_DEVICE:
      return { ...state, devices: state.devices.slice().map(e => (e._id === action.payload._id ? action.payload : e)) };
    case ActionTypes.DELETE_POS_DEVICE:
      return { ...state, devices: state.devices.slice().filter(e => (e._id !== action.payload._id)) };
    case ActionTypes.GET_POS_DEVICES:
      return { ...state, devices: action.payload };
    default:
      return state;
  }
};

export default posDeviceReducer;
