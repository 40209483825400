/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import '../../../styles/companies.scss';

export default (props) => {
  const { setting } = props;
  const { id, value, data } = setting;

  return (
    <div className="company-preview-area" onClick={() => { props.handleClick(setting); }}>
      <p>{`Setting: ${id}`}</p>
      <p>{`Active: ${value}`}</p>
      {data && Object.keys(data).length > 0 && (
        <p>{JSON.stringify(data)}</p>
      )}
    </div>
  );
};
