import axios from 'axios';
import * as firebase from 'firebase';

const sendPushNotificationToAllUsers = async (title, body) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.post(`${global.API_URL}/notifications/push/all`, {
    authToken: idToken,
    payload: {
      notification: {
        title,
        body,
      },
    },
    options: {
      priority: 'high',
      timeToLive: 60 * 60 * 24,
    },
  });
  return response.data;
};

const sendPushNotificationToUser = async (title, body, uid) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const response = await axios.post(`${global.API_URL}/notifications/push/${uid}`, {
    authToken: idToken,
    payload: {
      notification: {
        title,
        body,
      },
    },
    options: {
      priority: 'high',
      timeToLive: 60 * 60 * 24,
    },
  });
  return response.data;
};

const sendEmailReceiptToUser = async (uid, orderId) => {
  const response = await axios.post(`${global.API_URL}/notifications/emails/receipt/${orderId}`, { uid });
  return response.data;
};

export {
  sendEmailReceiptToUser,
  sendPushNotificationToAllUsers,
  sendPushNotificationToUser,
};
