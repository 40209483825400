import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  formatDate,
} from './utils';

const POSOrderPopup = (props) => {
  const {
    handleClose,
    order = {},
  } = props;

  if (Object.keys(order).length === 0) return null;

  return (
    <div>
      <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Order ID: {order.id}</DialogTitle>
        <DialogContent style={{ marginTop: '-15px', width: '450px' }}>
          <DialogContentText>
            {formatDate(new Date(order.time_opened))}
          </DialogContentText>
          <h3>Items:</h3>
          <ul>
            {Object.values(order.items).map(item => (
              <li>{item.productInfo.name}: {item.count}</li>
            ))}
          </ul>
          <h4 style={{ marginTop: 20, marginBottom: 5 }}>Subtotal: {order.subtotal ? `$${order.subtotal.toFixed(2)}` : '$0'}</h4>
          <h4 style={{ marginTop: 5, marginBottom: 5 }}>Total: {order.total ? `$${order.total.toFixed(2)}` : '$0'}</h4>
          <h4 style={{ marginTop: 5, marginBottom: 10 }}>Payment Method: {order.payment_method}</h4>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default POSOrderPopup;
