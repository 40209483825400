import * as userRequests from '../../services/user-requests';

// keys for actiontypes
const ActionTypes = {
  GET_ALL_USERS: 'GET_ALL_USERS',
};

const getAllUsers = () => {
  return async (dispatch) => {
    const data = await userRequests.getAllUsers();
    dispatch({ type: ActionTypes.GET_ALL_USERS, payload: data });
  };
};

const setUserPrivateCompanies = (uid, privateCompanies) => {
  return async (dispatch) => {
    await userRequests.setUserPrivateCompanies(uid, privateCompanies);
    const data = await userRequests.getAllUsers();
    dispatch({ type: ActionTypes.GET_ALL_USERS, payload: data });
  };
};

const setUserPrivateFridges = (uid, privateFridges) => {
  return async (dispatch) => {
    await userRequests.setUserPrivateFridges(uid, privateFridges);
    const data = await userRequests.getAllUsers();
    dispatch({ type: ActionTypes.GET_ALL_USERS, payload: data });
  };
};

const setUserActiveDiscount = (uid, activeDiscount) => {
  return async (dispatch) => {
    await userRequests.setUserActiveDiscount(uid, activeDiscount);
    const data = await userRequests.getAllUsers();
    dispatch({ type: ActionTypes.GET_ALL_USERS, payload: data });
  };
};

const setUserAdmin = (uid, bool) => {
  return async (dispatch) => {
    await userRequests.setUserAdmin(uid, bool);
    const data = await userRequests.getAllUsers();
    dispatch({ type: ActionTypes.GET_ALL_USERS, payload: data });
  };
};

const setUserStripeTestMode = (uid, bool) => {
  return async (dispatch) => {
    await userRequests.setUserStripeTestMode(uid, bool);
    const data = await userRequests.getAllUsers();
    dispatch({ type: ActionTypes.GET_ALL_USERS, payload: data });
  };
};

export {
  ActionTypes,
  getAllUsers,
  setUserPrivateCompanies,
  setUserPrivateFridges,
  setUserActiveDiscount,
  setUserAdmin,
  setUserStripeTestMode,
};
