/* eslint-disable camelcase */
/* eslint-disable max-len */

import React from 'react';
import '../../../styles/companies.scss';

export default ({
  id, name, fridge_id, passcode, handleClick,
}) => {
  return (
    <div
      className="company-preview-area"
      role="button"
      tabIndex={-1}
      onClick={() => {
        handleClick(id, name, fridge_id, passcode);
      }}
    >
      <p>{`Device ID: ${id}`}</p>
      <p>{`Device Name: ${name || 'Unnamed'}`}</p>
      <p>{`Fridge ID: ${fridge_id || 'Not set'}`}</p>
      <p>{`Passcode: ${passcode || 'Not set'}`}</p>
    </div>
  );
};
