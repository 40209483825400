import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MaterialTable from 'material-table';

import { TABLE_ICONS } from '../../../constants';

import {
  dateInLastMonth,
  formatDate,
  getUserName,
} from './utils';

import POSOrderPopup from './popup';

const columns = [
  { title: 'Date', field: 'time_opened' },
  { title: 'Subtotal', field: 'subtotal' },
  { title: 'Total', field: 'total' },
  { title: 'Payment', field: 'payment_method' },
  { title: 'Items', field: 'num_items', type: 'numeric' },
  { title: 'User', field: 'user_id' },
  { title: 'Order ID', field: 'id' },
];

const POSSales = (props) => {
  const {
    fridges,
    orders,
    users,
  } = props;

  const [selectedFridge, setSelectedFridge] = useState({});
  const [selectedOrder, setSelectedOrder] = useState({});

  const generateRowData = () => (
    orders
      .filter(order => order.fridge_id === selectedFridge.id)
      .map(order => ({
        time_opened: formatDate(new Date(order.time_opened)),
        id: order._id,
        user_id: getUserName(users, order),
        num_items: Object.keys(order.items).length,
        subtotal: order.subtotal ? `$${order.subtotal.toFixed(2)}` : '$0',
        total: order.total ? `$${order.total.toFixed(2)}` : '$0',
        payment_method: order.payment_method,
      }))
  );

  const handleRowClick = (_event, data) => {
    const foundOrder = orders.find(order => order.id === data.id);
    setSelectedOrder(foundOrder || {});
  };

  const filteredOrders = orders
    .filter(order => order.fridge_id === selectedFridge.id);

  return (
    <Grid container spacing={3}>
      <POSOrderPopup order={selectedOrder} handleClose={() => setSelectedOrder({})} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Paper className="paper-container" style={{ marginLeft: 10 }}>
          <h3>{selectedFridge.name || 'No Fridge Selected'}</h3>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {popupState => (
                <>
                  <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                Select Fridge
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    {Object.keys(selectedFridge).length > 0 && (
                      <MenuItem onClick={(e) => {
                        popupState.close(e);
                        setSelectedFridge({});
                      }}
                      >None
                      </MenuItem>
                    )}
                    {fridges
                      .filter(fridge => fridge.id !== selectedFridge.id)
                      .map(fridge => (
                        <MenuItem onClick={(e) => {
                          popupState.close(e);
                          setSelectedFridge(fridge);
                        }}
                        >{fridge.name}
                        </MenuItem>
                      ))}
                  </Menu>
                </>
              )}
            </PopupState>
          </div>
        </Paper>
        {Object.keys(selectedFridge).length > 0 && (
          <Paper className="paper-container" style={{ marginLeft: 10, textAlign: 'center' }}>
            <h3>Sales</h3>
            <h3 style={{ fontWeight: 'normal' }}>All Time: ${filteredOrders.reduce((acc, curr) => (acc + curr.subtotal), 0).toFixed(2)}</h3>
            <h3 style={{ fontWeight: 'normal' }}>Last 30 Days: ${filteredOrders
              .filter(order => (dateInLastMonth(new Date(order.time_opened))))
              .reduce((acc, curr) => (acc + curr.subtotal), 0).toFixed(2)}
            </h3>
          </Paper>
        )}
        {Object.keys(selectedFridge).length > 0 && (
          <Paper className="paper-container" style={{ marginLeft: 10, textAlign: 'center' }}>
            <h3>Number of Orders</h3>
            <h3 style={{ fontWeight: 'normal' }}>All Time: {filteredOrders.length}</h3>
            <h3 style={{ fontWeight: 'normal' }}>Last 30 Days: {filteredOrders
              .filter(order => (dateInLastMonth(new Date(order.time_opened)))).length}
            </h3>
          </Paper>
        )}
        {Object.keys(selectedFridge).length > 0 && (
          <Paper className="paper-container" style={{ marginLeft: 10, textAlign: 'center' }}>
            <h3>Items Sold</h3>
            <h3 style={{ fontWeight: 'normal' }}>All Time: {filteredOrders.reduce((acc, curr) => (acc + Object.keys(curr.items).length), 0)}</h3>
            <h3 style={{ fontWeight: 'normal' }}>Last 30 Days: {filteredOrders
              .filter(order => (dateInLastMonth(new Date(order.time_opened))))
              .reduce((acc, curr) => (acc + Object.keys(curr.items).length), 0)}
            </h3>
          </Paper>
        )}
      </div>
      <Grid item xs={15} md={11} lg={12}>
        <MaterialTable
          title="Point of Sale App Orders"
          icons={TABLE_ICONS}
          columns={columns}
          data={generateRowData()}
          onRowClick={handleRowClick}
          options={{
            exportButton: true,
            selection: true,
            pageSize: 25,
            pageSizeOptions: [25, 50, 75, 100],
          }}
        />
      </Grid>
    </Grid>
  );
};

export default POSSales;
