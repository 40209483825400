/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const DeviceView = ({
  fridges,
  handleInventoryUpdate,
  id,
  onClose,
  productMap,
  products,
}) => {
  const [fridge, setFridge] = useState();
  const [inventoryMap, setInventoryMap] = useState({});

  useEffect(() => {
    setFridge(fridges.find(f => f.id === id));
    setInventoryMap(fridges.find(f => f.id === id) ? fridges.find(f => f.id === id).inventory : {});
  }, [id]);

  const renderProductNameSelector = () => {
    const availableProducts = products.filter(p => !Object.keys(inventoryMap).includes(p.id));

    if (availableProducts.length === 0) {
      return null;
    }

    return (
      <div style={{
        display: 'flex', justifyContent: 'flex-start', marginBottom: '5px',
      }}
      >
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                Add Product
              </Button>
              <Menu {...bindMenu(popupState)}>
                {availableProducts.map((product) => {
                  return (
                    <MenuItem onClick={(e) => {
                      popupState.close(e);
                      setInventoryMap({
                        ...inventoryMap,
                        [product.id]: {
                          stock: 0,
                          current: 0,
                        },
                      });
                    }}
                    >{product.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </PopupState>
      </div>
    );
  };

  const renderAddItem = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '60px' }}>
        {renderProductNameSelector()}
      </div>
    );
  };

  if (fridge) {
    return (
      <div>
        <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{fridge.name || 'Set POS Inventory'}</DialogTitle>
          <DialogContent style={{ marginTop: '-15px', width: '450px' }}>
            <DialogContentText style={{ marginBottom: -10 }}>
              Edit the inventory of &quot;{fridge.name}&quot; below:
            </DialogContentText>

            {Object.entries(inventoryMap).map(([k, v]) => {
              return (
                <>
                  <Divider style={{ margin: '40px 0 12px 0' }} />
                  <p style={{ fontSize: 16, marginBottom: -2 }}>{productMap[k] ? productMap[k].name : `No Product Name, id="${k}"`}</p>
                  <TextField
                    required
                    margin="dense"
                    label="Total stocked"
                    defaultValue={v.stock}
                    onChange={(e) => {
                      setInventoryMap({
                        ...inventoryMap,
                        [k]: {
                          ...inventoryMap.k,
                          stock: parseInt(e.target.value, 10),
                        },
                      });
                    }}
                    fullWidth
                  />
                  <TextField
                    required
                    margin="dense"
                    label="Currently "
                    defaultValue={v.current}
                    onChange={(e) => {
                      setInventoryMap({
                        ...inventoryMap,
                        [k]: {
                          ...inventoryMap.k,
                          current: parseInt(e.target.value, 10),
                        },
                      });
                    }}
                    fullWidth
                  />
                  <div style={{ marginTop: 10, marginBottom: -10 }}>
                    <Button variant="outlined"
                      color="primary"
                      onClick={() => {
                        const newMap = Object.keys(inventoryMap)
                          .filter(key => key !== k)
                          .reduce((acc, curr) => {
                            return {
                              ...acc,
                              [curr]: inventoryMap[curr],
                            };
                          }, {});

                        setInventoryMap(newMap);
                      }}
                      style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    >
                      Delete Inventory Item
                    </Button>
                  </div>
                </>
              );
            })}

            {renderAddItem()}

            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '20px',
            }}
            >
              <Button variant="outlined"
                color="primary"
                onClick={() => { handleInventoryUpdate(inventoryMap); onClose(); }}
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
              >
                Submit
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return null;
};

export default DeviceView;
