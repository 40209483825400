import { ActionTypes } from '../actions';

const initialState = {
  promotions: [],
  currentPOSPromotions: [],
  currentMobilePromotions: [],
};

const PromotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_PROMOTIONS:
      return { ...state, promotions: action.payload };

    case ActionTypes.GET_CURRENT_POS_PROMOTIONS:
      return { ...state, currentPOSPromotions: action.payload };

    case ActionTypes.GET_CURRENT_MOBILE_PROMOTIONS:
      return { ...state, currentMobilePromotions: action.payload };

    default:
      return state;
  }
};

export default PromotionReducer;
