import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Edit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { updatePrivateCompany, deletePrivateCompany } from '../../../state/actions';

class ViewCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      name: '',
      valid_email_extensions: [],
    };
  }

  validEmailExtensionsArrayToString = (array) => {
    return array.join(', ');
  }

  validEmailExtensionsStringToArray = (string) => {
    this.setState({
      valid_email_extensions: string.split(', '),
    });
  }

  submit = () => {
    if (this.props.add) {
      this.props.createCompany(this.state);
    } else {
      this.props.updateCompany(this.state);
    }

    this.close();
  }

  delete = () => {
    this.props.deleteCompany(this.state.id);
    this.close();
  }

  close = () => {
    this.setState({
      id: '',
      name: '',
      valid_email_extensions: [],
    });

    this.props.handleClose();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if (props.company) {
      this.setState({
        id: props.company._id,
        name: props.company.name,
        valid_email_extensions: props.company.valid_email_extensions,
      });
    }
  }

  render() {
    if (this.props.company || this.props.add) {
      return (
        <div>
          <Dialog open onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{this.props.company ? this.props.company.name : 'Create Private Company'}</DialogTitle>
            <DialogContent style={{ marginTop: '-15px', width: '450px' }}>
              <DialogContentText>
                Edit details about this company below
              </DialogContentText>
              <TextField
                required
                margin="dense"
                label="Company name"
                defaultValue={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                fullWidth
              />
              <TextField
                required
                margin="dense"
                label="Valid email extensions, separated by commas"
                defaultValue={this.validEmailExtensionsArrayToString(this.state.valid_email_extensions)}
                onChange={e => this.validEmailExtensionsStringToArray(e.target.value)}
                fullWidth
              />
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Button variant="outlined"
                  color="primary"
                  onClick={this.submit}
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                >
                  Submit
                </Button>
                {this.props.deleteCompany
                  ? (
                    <Button variant="outlined"
                      color="primary"
                      onClick={this.delete}
                      style={{ marginLeft: '5px', marginRight: '5px' }}
                    >
                      Delete
                    </Button>
                  ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.close} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }

    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePrivateCompany: (fields) => {
      dispatch(updatePrivateCompany(fields));
    },
    deletePrivateCompany: (id) => {
      dispatch(deletePrivateCompany(id));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(ViewCompany);
