import { ActionTypes } from '../actions';

// initial state
const initialState = {
  fridges: [],
};

const FridgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_FRIDGES:
      return { ...state, fridges: action.payload };

    default:
      return state;
  }
};

export default FridgeReducer;
