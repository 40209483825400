import { connect } from 'react-redux';

import POSDevices from './component';

import { updateFridgeInventory } from '../../../state/actions';

const mapStateToProps = (state) => {
  const {
    fridges: {
      fridges,
    },
    products: {
      products,
    },
  } = state;

  return {
    fridges,
    productMap: products.reduce((accum, e) => ({ ...accum, [e._id || e.id]: e }), {}),
    products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFridgeInventory: (fridgeId, inventory) => {
      dispatch(updateFridgeInventory(fridgeId, inventory));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(POSDevices);
