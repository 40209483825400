import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as firebase from 'firebase';
import reducers from './state/reducers';
import App from './app';

import './styles/style.scss';

const store = createStore(reducers, {}, compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
));

// set the configuration for the app
const config = {
  apiKey: 'AIzaSyAouxtAu6-lOTYZGY9XZI81mjju5XpRr44',
  authDomain: 'dali-6amhealth.firebaseapp.com',
  databaseURL: 'https://dali-6amhealth.firebaseio.com',
  projectId: 'dali-6amhealth',
  storageBucket: 'dali-6amhealth.appspot.com',
  messagingSenderId: '1039983863584',
};

firebase.initializeApp(config);

const theme = createMuiTheme({
  palette: {
    primaryLight: {
      // light: will be calculated from palette.primary.main,
      main: '#B3DAC3',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#008037',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    primaryDark: {
      // light: will be calculated from palette.primary.main,
      main: '#00451E',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#D4E5B9',
      main: '#84C225',
      // dark: will be calculated from palette.secondary.main,
    },
    // error: will use the default color
  },
});

// we now wrap App in a Provider
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('main'),
);
