/* eslint-disable max-len */
import React from 'react';
import '../../../styles/companies.scss';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const POSDownloads = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper className="paper-container">
          <Typography component="h1" variant="h6" color="inherit" noWrap className={props.classes.title}>
            <b>POS App Downloads</b>
            <p>Click these links <b>from the iPad in Safari</b> in order to install. They will not work if you click them on a computer.</p>
            <p>If you are having problems downloading the app, send the device UDID to Thomas. Instructions <a href="https://bjango.com/help/iphoneudid/" target="_blank" rel="noopener noreferrer">here</a>.</p>
          </Typography>

          <Typography component="h2" variant="h6" color="inherit" noWrap className={props.classes.title}>
            <b>Release Builds: (use these on live fridges)</b>
          </Typography>
          <a href="itms-services://?action=download-manifest&url=https://pos-download-6amhealth.herokuapp.com/1.0.0/5/Download.plist">
            1.0.0(5)
          </a>
          <span>-- RELEASE v1.0.0</span>

          <br /><br />

          <Typography component="h2" variant="h6" color="inherit" noWrap className={props.classes.title}>
            <b>Beta Builds: (use these on test fridges)</b>
          </Typography>
          <a href="itms-services://?action=download-manifest&url=https://pos-download-6amhealth.herokuapp.com/1.0.0/1/Download.plist">
            1.0.0(1)
          </a>
          <br />
          <a href="itms-services://?action=download-manifest&url=https://pos-download-6amhealth.herokuapp.com/1.0.0/2/Download.plist">
            1.0.0(2)
          </a>
          <span>-- includes fixes for switching to manual credit card input when reader is connected</span>
          <br />
          <a href="itms-services://?action=download-manifest&url=https://pos-download-6amhealth.herokuapp.com/1.0.0/3/Download.plist">
            1.0.0(3)
          </a>
          <span>-- includes 30 min data retrieval + bug fix for menu category reset and empty product ingredients</span>
          <br />
          <a href="itms-services://?action=download-manifest&url=https://pos-download-6amhealth.herokuapp.com/1.0.0/4/Download.plist">
            1.0.0(4)
          </a>
          <span>-- sets receipt sent timeout to 6 seconds, adds text for thanking for supporting local business</span>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default POSDownloads;
