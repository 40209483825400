import { connect } from 'react-redux';

import POSSales from './component';

const mapStateToProps = (state) => {
  const {
    orders: {
      orders: allOrders,
    },
    fridges: {
      fridges,
    },
    users: {
      users,
    },
  } = state;

  const orders = allOrders.filter(order => !order.is_private);

  return {
    fridges,
    orders,
    users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(POSSales);
